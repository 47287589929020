import { AxiosPromise, AxiosRequestConfig } from "axios";
import { RefetchOptions } from "axios-hooks";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import generateAdminReport from "./generateAdminReport";
import generateDepartmentResultSummary from "./generateDepartmentResultSummary";
import generateDivisionResultSummary from "./generateDivisionResultSummary";
import generateOverallResultSummary from "./generateOverallResultSummary";
import { DateRange, FilterState } from "../Reports";
import { 
    IPublishedRecordSetDocument, 
    isIPublishedEntry, 
    isIPublishedRecordSetDocument 
} from "../../DatasetDetails/PublishedRecordSet";
import { 
    GetReportsData_reportsData, 
    GetReportsData_reportsData_publishedRecordSets 
} from "../../../graphql/__generated__/GetReportsData";
import { groupedByMonthYearCategory, IChartData } from "../../../selectors/ChartData";


export enum ResultSummarySectionType {
    ADMIN = "ADMIN",
    NORMAL = "NORMAL",
}

export enum ResultSummaryType {
    Admin = "admin",
    Department = "department",
    Division = "division",
    Overall = "overall",
}


export type DatasetHighPerformersForCategory = {
    datasetNames: string[];
    categoryName: string;
    result: number;
}

export type ExtraResultSummaryStats = {
    numberOfTeamsCreated: {
        currentMonth: number;
        previousMonth: number;
    };
    numberOfDatasetsCreated: {
        currentMonth: number;
        previousMonth: number;
    };
    highPerformingDatasets: {
        categoryName: string;
        fullYear: number;
        halfYear: number;
    };
    annualProgress: {
        categoryName: string;
        datasetsOnTarget: {
            proportion : number;
            year: string;
        }[];
    }[];
}

export type GetExtraResultSummaryStats = (
    config?: AxiosRequestConfig<GetExtraResultSummaryStatsPayload>,
    options?: RefetchOptions
) => AxiosPromise<ExtraResultSummaryStats>

export type GetExtraResultSummaryStatsPayload = {
    filters: FilterState;
    range: DateRange;
    type: ResultSummaryType
}

export type ResultSummary = {
    title: string;
    sections: ResultSummarySection[];
}

export type ResultSummaryContent = {
    id: string;
    lines: ResultSummaryLine[];
}

export type ResultSummaryData = {
    categories: string[];
    filterState: FilterState;
    isAdmin: boolean;
    reportsData: ResultSummaryReportsData;
    tz: string;
}

export type ResultSummaryLine = {
    id: string;
    text: string;
}

export type ResultSummaryReportsData = {
    currentMonth: GetReportsData_reportsData;
    previousMonth: GetReportsData_reportsData;
}

export type ResultSummarySection = {
    id: string;
    type: ResultSummarySectionType
    header?: string;
    content: ResultSummaryContent[];
}


const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";

export const NO_DATA_TO_DISPLAY_SECTION: ResultSummarySection = { 
    id: uuidv4(),
    type: ResultSummarySectionType.NORMAL,
    content: [{ 
        id: uuidv4(),
        lines: [
            {
                id: uuidv4(),
                text: "There is no data to summarise. Please change your filter selections and ensure there is data displayed on the page." 
            }
        ]
    }]
};


export const stringIsNotUndefined = (string: string | undefined): string is string => !!string;

export const describeNumericChange = (current = 0, previous = 0): string => {
    if (current > previous) {
        return "up";
    } else if (current < previous) {
        return "down";
    } else {
        return "no change";
    }
};

export const getRoundedPercent = (numerator: number, denominator: number) => Math.round((numerator / denominator) * 100);

export const getPercentTeamsOnTargetFromGroupedPublishedRecords = (
    groupedPublishedRecords: Record<string, IChartData> | undefined,
    category: string,
): number => {
    let percent = 0;
    if (groupedPublishedRecords) {
        const data = Object.values(groupedPublishedRecords).find(item => item.category === category);
        if (data) {
            percent = getRoundedPercent(data.exceeds, data.count);
        }    
    }
    return percent;
};

export const getTotalNumberOfDatasets = (reportsData: GetReportsData_reportsData): number => {
    const { publishedRecordSets, unpublishedDatasets, offAirDatasets } = reportsData;
    const concatDatasets = [
        ...publishedRecordSets.map(published => published.dataset?.id),
        ...unpublishedDatasets.map(unpublished => unpublished.dataset?.id),
        ...offAirDatasets.map(offair => offair.id),
    ];
    return new Set(concatDatasets).size;
};

export const getTotalNumberOfTeams = (reportsData: GetReportsData_reportsData): number => {
    const { publishedRecordSets, unpublishedDatasets, offAirDatasets } = reportsData;
    const concatTeams = [
        ...publishedRecordSets.map(published => published.dataset?.program?.team?.id),
        ...unpublishedDatasets.map(unpublished => unpublished.dataset?.program?.team?.id),
        ...offAirDatasets.map(offair => offair.program?.team?.id),
    ];
    return new Set(concatTeams).size;
};

export const groupPublishedRecords = (
    categories: string[],
    publishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
    tz: string,
): Record<string, IChartData> | undefined => {
    const parsed = publishedRecords.map(x => ({
        ...x,
        begin: dayjs(x.begin, EXPECTED_DATE_TIME_FORMAT, tz),
        end: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, tz),
    })).sort((a, b) => a.end.unix() - b.end.unix());
    const grouped = groupedByMonthYearCategory(parsed, categories);
    return Object.values(grouped)[0];
};

export const filterReportsDataByFilterState = (data: ResultSummaryData): ResultSummaryData => {
    const { filterState, reportsData } = data;
    let filteredCurrentMonth = reportsData.currentMonth;
    let filteredPreviousMonth = reportsData.previousMonth;

    if (filterState.departments.length > 0) {
        filteredCurrentMonth = filterReportsDataByDepartments(filterState, filteredCurrentMonth);
        filteredPreviousMonth = filterReportsDataByDepartments(filterState, filteredPreviousMonth);
    }
    if (filterState.divisions.length > 0) {
        filteredCurrentMonth = filterReportsDataByDivisions(filterState, filteredCurrentMonth);
        filteredPreviousMonth = filterReportsDataByDivisions(filterState, filteredPreviousMonth);
    }
    if (filterState.miscTags.length > 0) {
        filteredCurrentMonth = filterReportsDataByMiscTags(filterState, filteredCurrentMonth);
        filteredPreviousMonth = filterReportsDataByMiscTags(filterState, filteredPreviousMonth);
    }
    if (filterState.programs.length > 0) {
        filteredCurrentMonth = filterReportsDataByPrograms(filterState, filteredCurrentMonth);
        filteredPreviousMonth = filterReportsDataByPrograms(filterState, filteredPreviousMonth);
    }
    if (filterState.teams.length > 0) {
        filteredCurrentMonth = filterReportsDataByTeams(filterState, filteredCurrentMonth);
        filteredPreviousMonth = filterReportsDataByTeams(filterState, filteredPreviousMonth);
    }

    return { ...data, reportsData: { currentMonth: filteredCurrentMonth, previousMonth: filteredPreviousMonth } };
};

const filterReportsDataByDepartments = (
    filterState: FilterState,
    reportsData: GetReportsData_reportsData,
): GetReportsData_reportsData => ({
    ...reportsData,
    offAirDatasets: reportsData.offAirDatasets.filter(item => (
        filterState.departments.some(({ id }) => id === item.program?.department?.id)
    )),
    publishedRecordSets: reportsData.publishedRecordSets.filter(item => (
        filterState.departments.some(({ id }) => id === item.dataset?.program?.department?.id)
    )),
    publishedRecordSetsPreviousOne: reportsData.publishedRecordSetsPreviousOne.filter(item => (
        filterState.departments.some(({ id }) => id === item.dataset?.program?.department?.id)
    )),
    unpublishedDatasets: reportsData.unpublishedDatasets.filter(item => (
        filterState.departments.some(({ id }) => id === item.dataset?.program?.department?.id)
    )),
});

const filterReportsDataByDivisions = (
    filterState: FilterState,
    reportsData: GetReportsData_reportsData,
): GetReportsData_reportsData => ({
    ...reportsData,
    offAirDatasets: reportsData.offAirDatasets.filter(item => (
        filterState.divisions.some(({ id }) => id === item.program?.department?.division?.id)
    )),
    publishedRecordSets: reportsData.publishedRecordSets.filter(item => (
        filterState.divisions.some(({ id }) => id === item.dataset?.program?.department?.division?.id)
    )),
    publishedRecordSetsPreviousOne: reportsData.publishedRecordSetsPreviousOne.filter(item => (
        filterState.divisions.some(({ id }) => id === item.dataset?.program?.department?.division?.id)
    )),
    unpublishedDatasets: reportsData.unpublishedDatasets.filter(item => (
        filterState.divisions.some(({ id }) => id === item.dataset?.program?.department?.division?.id)
    )),
});

const filterReportsDataByMiscTags = (
    filterState: FilterState,
    reportsData: GetReportsData_reportsData,
): GetReportsData_reportsData => ({
    ...reportsData,
    offAirDatasets: reportsData.offAirDatasets.filter(item => (
        filterState.miscTags.some(({ id }) => item.program?.tags.some(tag => tag.id === id))
    )),
    publishedRecordSets: reportsData.publishedRecordSets.filter(item => (
        filterState.miscTags.some(({ id }) => item.dataset?.program?.tags.some(tag => tag.id === id))
    )),
    publishedRecordSetsPreviousOne: reportsData.publishedRecordSetsPreviousOne.filter(item => (
        filterState.miscTags.some(({ id }) => item.dataset?.program?.tags.some(tag => tag.id === id))
    )),
    unpublishedDatasets: reportsData.unpublishedDatasets.filter(item => (
        filterState.miscTags.some(({ id }) => item.dataset?.program?.tags.some(tag => tag.id === id))
    )),
});

const filterReportsDataByPrograms = (
    filterState: FilterState,
    reportsData: GetReportsData_reportsData,
): GetReportsData_reportsData => ({
    ...reportsData,
    offAirDatasets: reportsData.offAirDatasets.filter(item => (
        filterState.programs.some(({ id }) => item.program?.id === id)
    )),
    publishedRecordSets: reportsData.publishedRecordSets.filter(item => (
        filterState.programs.some(({ id }) => item.dataset?.program?.id === id)
    )),
    publishedRecordSetsPreviousOne: reportsData.publishedRecordSetsPreviousOne.filter(item => (
        filterState.programs.some(({ id }) => item.dataset?.program?.id === id)
    )),
    unpublishedDatasets: reportsData.unpublishedDatasets.filter(item => (
        filterState.programs.some(({ id }) => item.dataset?.program?.id === id)
    )),
});

const filterReportsDataByTeams = (
    filterState: FilterState,
    reportsData: GetReportsData_reportsData,
): GetReportsData_reportsData => ({
    ...reportsData,
    offAirDatasets: reportsData.offAirDatasets.filter(item => (
        filterState.teams.some(({ id }) => item.program?.team?.id === id)
    )),
    publishedRecordSets: reportsData.publishedRecordSets.filter(item => (
        filterState.teams.some(({ id }) => item.dataset?.program?.team?.id === id)
    )),
    publishedRecordSetsPreviousOne: reportsData.publishedRecordSetsPreviousOne.filter(item => (
        filterState.teams.some(({ id }) => item.dataset?.program?.team?.id === id)
    )),
    unpublishedDatasets: reportsData.unpublishedDatasets.filter(item => (
        filterState.teams.some(({ id }) => item.dataset?.program?.team?.id === id)
    )),
});

export const getHighPerformersForCategories = (
    categories: string[],
    publishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
): DatasetHighPerformersForCategory[] => {
    const validPublishedRecords = publishedRecords
        .filter(publishedRecord => isIPublishedRecordSetDocument(publishedRecord.document));

    return categories.map(category => {
        const filteredPublishedRecords = validPublishedRecords
            .filter(publishedRecord => (
                (publishedRecord.document as IPublishedRecordSetDocument).record["Everyone"]?.[category]
            ));

        const { datasetNames, result } = filteredPublishedRecords.reduce((max, { dataset, document }) => {
            const datasetName = dataset?.name;
            const entries = Object.values(
                (document as IPublishedRecordSetDocument).record["Everyone"][category]?.entries
            );
            const filteredEntries = entries
                .filter(isIPublishedEntry)
                .filter(entry => entry.targetMember === true);

            const result = filteredEntries.reduce((acc, curr) => acc + curr.percent, 0);

            if (result > max.result && datasetName) {
                return { result, datasetNames: [datasetName]};
            } else if (result === max.result && datasetName) {
                return { result, datasetNames: [...max.datasetNames, datasetName]};
            } else {
                return max;
            }
        }, { datasetNames: [] as string[], result: 0 });
    
        return { categoryName: category, datasetNames, result };
    });
};


export default { 
    generateOverallResultSummary, 
    generateDivisionResultSummary, 
    generateDepartmentResultSummary, 
    generateAdminReport
};
