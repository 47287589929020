import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";

import { DateRange } from "../hooks/DatasetDetailsFilterProvider";
import {
    GetDataset,
    GetDataset_dataset_program_targets,
    GetDataset_dataset_program_targets_category,
    GetDataset_dataset_records,
    GetDataset_dataset_records_entries
} from "../graphql/__generated__/GetDataset";
import { sortDiversityCategories } from "../utils/sortDiversityCategories";

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

export type TargetState = {
    target: GetDataset_dataset_program_targets;
    status: number;
    attributesInTarget: string[];
    attributesOOTarget: string[];
}


const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";

const sumOfEntriesByAttributeCategory = (
    entries: readonly GetDataset_dataset_records_entries[],
    attributeCategory: GetDataset_dataset_program_targets_category,
) => entries.reduce((prevEntry, currEntry) => (
    currEntry.categoryValue.category.id === attributeCategory.id ? currEntry.count + prevEntry : prevEntry
), 0);

const sumOfRecordsByAttributeCategory = (
    records: readonly GetDataset_dataset_records[],
    attributeCategory: GetDataset_dataset_program_targets_category,
) => records.reduce((prev, curr) => (prev + sumOfEntriesByAttributeCategory(curr.entries, attributeCategory)), 0);

const sumOfEntriesByInTargetAttribute = (
    entries: readonly GetDataset_dataset_records_entries[],
    attributesInTarget: string[]
) => entries.reduce((prevEntry, currEntry) => (
    attributesInTarget.includes(currEntry.categoryValue.name) ? currEntry.count + prevEntry : prevEntry
), 0);

const sumOfRecordsByInTargetAttribute = (
    records: readonly GetDataset_dataset_records[],
    attributesInTarget: string[]
) => records.reduce((prev, curr) => (prev + sumOfEntriesByInTargetAttribute(curr.entries, attributesInTarget)), 0);

const percentOfInTargetAttributeCategories = (
    records: readonly GetDataset_dataset_records[],
    category: GetDataset_dataset_program_targets_category,
    attributesInTarget: string[]
) => Math.round((
    sumOfRecordsByInTargetAttribute(records, attributesInTarget) / sumOfRecordsByAttributeCategory(records, category)
) * 100);

const isTargetMember = (queryData: GetDataset, categoryValueId: string) => queryData?.dataset?.program?.targets
    .flat()
    .flatMap(x => x.tracks)
    .find((track) => track.categoryValue.id === categoryValueId)
    ?.targetMember ?? false;

export const sortedRecords = (queryData: GetDataset | undefined, timezoneName: string) => {
    if (queryData?.dataset?.records) {
        return Array.from(queryData.dataset.records)
            .map((x) => ({
                ...x,
                publicationDate: dayjs(x.publicationDate, EXPECTED_DATE_TIME_FORMAT, timezoneName),
                entries: Array.from(x.entries).sort((a, b) =>
                    Number(isTargetMember(queryData, a.categoryValue.id)) - Number(isTargetMember(queryData, b.categoryValue.id))
                ),
            }))
            .sort((a, b) => a.publicationDate.unix() - b.publicationDate.unix());
    }
};

export const filteredRecords = (
    queryData: GetDataset | undefined, dateRangeFilters: DateRange, timezoneName: string
) => {
    const sr = sortedRecords(queryData, timezoneName);
    if (dateRangeFilters && sr) {
        if (dateRangeFilters.length === 2) {
            const from = dateRangeFilters[0];
            const to = dateRangeFilters[1];

            return (from && to) ? sr.filter((record) => record.publicationDate.isBetween(from, to, "day", "[]")) : [];
        }
    }
    return [];
};

export const targetStates = (
    queryData: GetDataset | undefined,
    dateRangeFilters: DateRange,
    timezoneName: string
): TargetState[] | undefined => {
    return queryData?.dataset?.program?.targets
        .map((target) => {
            const attributesInTarget = queryData?.dataset?.program?.targets
                .find((x) => x.category.id === target.category.id)
                ?.tracks
                .filter(x => x.targetMember)
                .map((x) => x.categoryValue.name) ?? new Array<string>();
            const attributesOOTarget = queryData?.dataset?.program?.targets
                .find((x) => x.category.id === target.category.id)
                ?.tracks
                .filter(x => !x.targetMember)
                .map((x) => x.categoryValue.name) ?? new Array<string>();
            const status = percentOfInTargetAttributeCategories(
                filteredRecords(queryData, dateRangeFilters, timezoneName),
                target.category,
                attributesInTarget
            );
            return { target: target, status: status, attributesInTarget, attributesOOTarget };
        })
        .sort((a, b) => sortDiversityCategories(a.target.category.name, b.target.category.name));
};