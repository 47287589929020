import { FilterState } from "../pages/Reports/Reports";


export enum ImageFormat {
    PNG = "png",
    JPEG = "jpeg",
}

export type FilenameProps = {
    suffix: string;
    filterState: FilterState;
}


export const createExportFilename = ({ suffix, filterState }: FilenameProps): string => {
    const { divisions, departments, miscTags, programs, range } = filterState;

    const dateFormat = "MMMYY";
    const dateString = range.begin.month() === range.end.month() ? `${range.begin.format(dateFormat)}` 
        : `${range.begin.format(dateFormat)} to ${range.end.format(dateFormat)}`;
    
    const filterConcat = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ];
    const filterStringBase = filterConcat.length > 0 ? filterConcat[0] : "";
    const filterString = filterConcat.length > 1 ? `${filterStringBase} and others` : filterStringBase;
    
    const filename = filterString ? `${dateString}-${filterString}` : `${dateString}`;
    return `${filename.toLowerCase()}-${suffix.toLowerCase()}`;
};

// TODO - Convert to web workers
export const downloadSvgAsImage = async (
    svgContainerId: string,
    filename: string,
    imageFormat: ImageFormat
): Promise<void> => {
    return new Promise((resolve, reject) => {
        const svgContainer = document.getElementById(svgContainerId);
        if (!svgContainer) {
            return reject();
        }

        const svg = svgContainer.querySelector("svg");
        if (!svg) {
            return reject();
        }

        const svgString = (new XMLSerializer()).serializeToString(svg);
        const svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
        
        const url = URL.createObjectURL(svgBlob);
        const image = new Image();
        image.width = svg.width.baseVal.value;
        image.height = svg.height.baseVal.value;
        image.src = url;
        image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;

            const context = canvas.getContext("2d");
            if (!context) {
                return reject();
            }

            context.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
                if (!blob) {
                    return reject();
                }

                const anchor = document.createElement("a");
                const url = URL.createObjectURL(blob);
                anchor.download = `${filename}.${imageFormat}`;
                anchor.target = "_blank";
                anchor.href = url;

                anchor.dispatchEvent(
                    new MouseEvent("click", { view: window, bubbles: false, cancelable: true })
                );

                return resolve();
            }, `image/${imageFormat}`, 1.0);
        };
    });
};