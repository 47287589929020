import { InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Card, Col, Divider, Modal, Radio, RadioChangeEvent, Row, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useBulkEditReportingPeriods } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import {
    AdminGetProgramsOnBulkEditOfReportingPeriods,
    AdminGetProgramsOnBulkEditOfReportingPeriods_programsOnBulkEditOfReportingPeriods
} from "../../../graphql/__generated__/AdminGetProgramsOnBulkEditOfReportingPeriods";
import { NewReportingPeriodsInput, ReportingPeriodType } from "../../../graphql/__generated__/globalTypes";
import { 
    ADMIN_GET_PROGRAMS_ON_BULK_EDIT_OF_REPORTING_PERIODS
} from "../../../graphql/__queries__/AdminGetProgramsOnBulkEditOfReportingPeriods.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";

dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(utc);


enum ErrorEnum {
    "NoProgramsSelected",
}

enum ToggleProgramDisplayEnum {
    "All" = "Display all datasets",
    "NeedNewReportingPeriods" = "Only display datasets that need new reporting periods"
}

enum ToggleSelectAll {
    SELECT_ALL = "Select All",
    DESELECT_ALL = "Deselect All",
}

type ProgramDisplayProps = {
    key: string;
    name: string;
    reportingPeriodType: ReportingPeriodType;
    reportingPeriodsNeeded: NewReportingPeriodsInput[];
    dateLastPublished: string;
};

type ReportingPeriodCardProps = {
    display: string;
    value: ReportingPeriodType;
};

type ReportingPeriodsUntilEndOfNextAnnualCycle = {
    [ReportingPeriodType.monthly]: NewReportingPeriodsInput[];
    [ReportingPeriodType.quarterly]: NewReportingPeriodsInput[];
    [ReportingPeriodType.annual]: NewReportingPeriodsInput[];
    [ReportingPeriodType.custom]: [];
};


const cardStyle = { border: "3px solid #f0f0f0", borderRadius: "5px", background: "#fafafa" };
const selectedCardStyle = {
    ...cardStyle,
    border: "1px solid blue",
    boxShadow: "0.2em 0.3em 0.75em rgba(0,0,50,0.3)"
};

const REPORTING_PERIOD_TYPES: ReportingPeriodCardProps[] = [
    { display: "Monthly", value: ReportingPeriodType.monthly },
    { display: "Quarterly", value: ReportingPeriodType.quarterly },
    { display: "Annual", value: ReportingPeriodType.annual },
    { display: "Custom", value: ReportingPeriodType.custom }
];
const DEFAULT_DATE_LAST_PUBLISHED = "N/A";
const DEFAULT_REPORTING_PERIODS_UNTIL_END_OF_NEXT_ANNUAL_CYCLE: ReportingPeriodsUntilEndOfNextAnnualCycle = {
    [ReportingPeriodType.monthly]: [],
    [ReportingPeriodType.quarterly]: [],
    [ReportingPeriodType.annual]: [],
    [ReportingPeriodType.custom]: [],
};

const enforceDateCleansing = (date: dayjs.Dayjs) => date.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0).tz("UTC", true).toISOString();

const getReportingPeriodsUntilEndOfNextAnnualCycle = (): ReportingPeriodsUntilEndOfNextAnnualCycle => {
    const now = dayjs();
    const monthlyReportingPeriodsUntilEndOfNextAnnualCycle: NewReportingPeriodsInput[] = [];
    const quarterlyReportingPeriodsUntilEndOfNextAnnualCycle: NewReportingPeriodsInput[] = [];
    const annualReportingPeriodsUntilEndOfNextAnnualCycle: NewReportingPeriodsInput[] = [];

    const monthsUntilEndOfNextAnnualCycle = now.add(1, "year").endOf("year").diff(now, "month");
    for (let i = 0; i < monthsUntilEndOfNextAnnualCycle; i++) {
        const startDate = now.add((i + 1), "month").startOf("month");
        const endDate = startDate.endOf("month");
        monthlyReportingPeriodsUntilEndOfNextAnnualCycle.push({
            begin: enforceDateCleansing(startDate),
            end: enforceDateCleansing(endDate),
            description: `${startDate.format("MMMM")} ${startDate.year()}`,
        });
    }

    const quartersUntilEndOfNextAnnualCycle = now.add(1, "year").endOf("year").diff(now, "quarter");
    for (let i = 0; i < quartersUntilEndOfNextAnnualCycle; i++) {
        const startDate = now.add((i + 1), "quarter").startOf("quarter");
        const endDate = startDate.endOf("quarter");
        quarterlyReportingPeriodsUntilEndOfNextAnnualCycle.push({
            begin: enforceDateCleansing(startDate),
            end: enforceDateCleansing(endDate),
            description: `Quarter ${startDate.quarter()} - ${startDate.year()}`,
        });
    }

    const startDate = now.quarter() === 1 ? now.month(3).startOf("month") : now.add(1, "year").month(3).startOf("month");
    const endDate = startDate.add(1, "year").subtract(1, "month").endOf("month");
    annualReportingPeriodsUntilEndOfNextAnnualCycle.push({
        begin: enforceDateCleansing(startDate),
        end: enforceDateCleansing(endDate),
        description: `${startDate.year()} - ${endDate.year()}`
    });

    return {
        [ReportingPeriodType.monthly]: monthlyReportingPeriodsUntilEndOfNextAnnualCycle,
        [ReportingPeriodType.quarterly]: quarterlyReportingPeriodsUntilEndOfNextAnnualCycle,
        [ReportingPeriodType.annual]: annualReportingPeriodsUntilEndOfNextAnnualCycle,
        [ReportingPeriodType.custom]: [],
    };
};

const mapReportingPeriodTypeToNumberPerAnnualCycle = {
    [ReportingPeriodType.monthly]: 12,
    [ReportingPeriodType.quarterly]: 4,
    [ReportingPeriodType.annual]: 1
};


export const BulkEditReportingPeriods = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const bulkEditReportingPeriods = useBulkEditReportingPeriods();

    const [
        reportingPeriodsUntilEndOfNextAnnualCycle,
        setReportingPeriodsUntilEndOfNextAnnualCycle
    ] = useState<ReportingPeriodsUntilEndOfNextAnnualCycle>(DEFAULT_REPORTING_PERIODS_UNTIL_END_OF_NEXT_ANNUAL_CYCLE);
    const [reportingPeriodType, setReportingPeriodType] = useState<ReportingPeriodType>(ReportingPeriodType.monthly);
    const [displayProgramsToggle, setDisplayProgramsToggle] = useState<ToggleProgramDisplayEnum>(ToggleProgramDisplayEnum.NeedNewReportingPeriods);
    const [selectAllButtonText, setSelectAllButtonText] = useState<ToggleSelectAll>(ToggleSelectAll.DESELECT_ALL);
    const [showConfirmSubmission, setShowConfirmSubmission] = useState<boolean>(false);
    const [selectedProgramIds, setSelectedProgramIds] = useState<string[]>([]);
    const [error, setError] = useState<ErrorEnum | undefined>(undefined);

    const rawPrograms = useQueryWithErrorHandling<AdminGetProgramsOnBulkEditOfReportingPeriods>(
        ADMIN_GET_PROGRAMS_ON_BULK_EDIT_OF_REPORTING_PERIODS,
        "programsOnBulkEditOfReportingPeriods",
        {
            fetchPolicy: "network-only"
        }
    );

    const expectedDateTimeFormat = "YYYY-M-DTHH:mm:ss";
    const tz = dayjs.tz.guess();

    useMemo(() => setReportingPeriodsUntilEndOfNextAnnualCycle(getReportingPeriodsUntilEndOfNextAnnualCycle()), []);

    const programs = useMemo<ProgramDisplayProps[] | undefined>(() => rawPrograms.data?.programsOnBulkEditOfReportingPeriods.map(program => {
        const getDateLastPublished = (program: AdminGetProgramsOnBulkEditOfReportingPeriods_programsOnBulkEditOfReportingPeriods) => {
            const isString = (item: string | undefined): item is string => !!item;

            const endDates = program.datasets.flatMap(dataset => {
                if (dataset.publishedRecordSets) {
                    return dataset.publishedRecordSets.map(
                        publishedRecordSet => dayjs(publishedRecordSet.end, expectedDateTimeFormat, tz).toISOString()
                    );
                }
            }).filter(isString);

            let dateLastPublished = DEFAULT_DATE_LAST_PUBLISHED;
            if (endDates.length > 0) {
                if (endDates.length > 1) {
                    return dayjs(Math.max(...endDates.map(dateString => dayjs(dateString).toDate().getTime()))).toString();
                }

                dateLastPublished = endDates[0];
            }

            return dateLastPublished;
        };

        const getReportingPeriodsNeeded = (program: AdminGetProgramsOnBulkEditOfReportingPeriods_programsOnBulkEditOfReportingPeriods) => {
            const { reportingPeriodType, reportingPeriods } = program;

            const initialReportingPeriodsNeeded: NewReportingPeriodsInput[] = reportingPeriodsUntilEndOfNextAnnualCycle[reportingPeriodType];
            const reportingPeriodsNeeded = initialReportingPeriodsNeeded.filter(({ end: endCalc }) => (
                reportingPeriods && !reportingPeriods?.some(({ end: endActual }) => dayjs(endCalc).isSame(dayjs(endActual), "day"))
            ));

            return reportingPeriodsNeeded;
        };

        return {
            key: program.id,
            name: program.name,
            reportingPeriodType: program.reportingPeriodType,
            reportingPeriodsNeeded: getReportingPeriodsNeeded(program),
            dateLastPublished: getDateLastPublished(program),
        };
    }), [rawPrograms, reportingPeriodsUntilEndOfNextAnnualCycle, tz]
    );

    const filteredData = useMemo(() => {
        const filtered = (
            programs
                ?.filter(program => program.reportingPeriodType.toLocaleLowerCase() === reportingPeriodType)
                .filter(program => (
                    displayProgramsToggle === ToggleProgramDisplayEnum.NeedNewReportingPeriods 
                        ? program.reportingPeriodsNeeded.length > 0 : program
                ))
        );

        setSelectedProgramIds(filtered?.map(item => item.key) || []);
        return filtered;
    }, [programs, reportingPeriodType, displayProgramsToggle]);

    const sortOnDateLastPublished = (a: ProgramDisplayProps, b: ProgramDisplayProps): number => {
        if (a.dateLastPublished === DEFAULT_DATE_LAST_PUBLISHED) {
            return -1;
        }
        if (b.dateLastPublished === DEFAULT_DATE_LAST_PUBLISHED) {
            return 1;
        }
        return dayjs(a.dateLastPublished).toDate().getTime() < dayjs(b.dateLastPublished).toDate().getTime() ? -1 : 1;
    };

    const toggleSelectAll = () => {
        if (selectAllButtonText === ToggleSelectAll.SELECT_ALL) {
            setSelectedProgramIds(filteredData?.map(item => item.key) || []);
            setSelectAllButtonText(ToggleSelectAll.DESELECT_ALL);
        } else {
            setSelectedProgramIds([]);
            setSelectAllButtonText(ToggleSelectAll.SELECT_ALL);
        }
    };

    const columns: ColumnsType<ProgramDisplayProps> = [
        {
            title: t("admin.program.reportingPeriods.table.headers.name"),
            dataIndex: "name",
            defaultSortOrder: "ascend",
            sorter: (a, b) => (a.name.localeCompare(b.name)),
            render: (text, record) => <a href={`/admin/programs/${record.key}`}>{text}</a>
        },
        {
            title: t("admin.program.reportingPeriods.table.headers.dateLastPublished"),
            dataIndex: "dateLastPublished",
            width: "25%",
            sorter: sortOnDateLastPublished
        }
    ];

    const rowSelection: TableRowSelection<ProgramDisplayProps> = {
        columnWidth: 80,
        selectedRowKeys: selectedProgramIds,
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedProgramIds(selectedRowKeys.map(key => key.toString()));
            setError(undefined);
        },
    };

    const toggleProgramsDisplayed = (event: RadioChangeEvent) => setDisplayProgramsToggle(event.target.value);

    const validateInputsAndShowConfirmModal = () => {
        if (selectedProgramIds.length === 0) {
            setError(ErrorEnum.NoProgramsSelected);
        } else {
            setError(undefined);
            setShowConfirmSubmission(true);
        }
    };

    const resetPage = () => {
        setReportingPeriodType(ReportingPeriodType.monthly);
        setDisplayProgramsToggle(ToggleProgramDisplayEnum.NeedNewReportingPeriods);
        setSelectAllButtonText(ToggleSelectAll.DESELECT_ALL);
        setSelectedProgramIds([]);
        rawPrograms.refetch();
    };

    const submitBulkEdit = async () => {
        setShowConfirmSubmission(false);

        await bulkEditReportingPeriods.run({
            programs: selectedProgramIds.map(id => ({ 
                id, 
                reportingPeriodType, 
                newReportingPeriods: programs?.find(program => program.key === id)?.reportingPeriodsNeeded || null
            }))
        });

        resetPage();
    };

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={t("admin.program.reportingPeriods.title")} />

            {/* Page Header */}
            <PageHeader
                onBack={() => navigate("/admin/programs")}
                title={t("admin.program.reportingPeriods.title")}
            />

            {/* Action Error */}
            {bulkEditReportingPeriods.error &&
                <Row style={{ paddingBottom: 16 }}>
                    <Col span={24}>
                        <Alert
                            type="error"
                            showIcon
                            closable
                            message={t("admin.program.reportingPeriods.form.validation.bulkEditReportingPeriodsError")}
                        />
                    </Col>
                </Row>
            }

            {/* Select Type of Reporting Period */}
            <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
                {REPORTING_PERIOD_TYPES.map(({ display, value }) =>
                    <Col key={`${value}-card`} span={6}>
                        <div
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onKeyDown={(e) => { if (e.key === "Enter") { setReportingPeriodType(value); } }}
                            onClick={() => setReportingPeriodType(value)}
                        >
                            <Card style={reportingPeriodType === value ? selectedCardStyle : cardStyle}>
                                {display}
                            </Card>
                        </div>
                    </Col>)}
            </Row>

            {/* Filter Results */}
            <Row gutter={[16, 8]} style={{ paddingBottom: 16 }} align="middle">
                <Divider orientation="left">{t("admin.program.reportingPeriods.filterToggle.heading")}</Divider>
                <Col offset={1}>
                    <Radio.Group onChange={toggleProgramsDisplayed} value={displayProgramsToggle}>
                        <Radio value={ToggleProgramDisplayEnum.All}>
                            {ToggleProgramDisplayEnum.All}
                        </Radio>
                        <Radio value={ToggleProgramDisplayEnum.NeedNewReportingPeriods}>
                            {ToggleProgramDisplayEnum.NeedNewReportingPeriods}
                        </Radio>
                    </Radio.Group>
                </Col>
                <Tooltip title={t("admin.program.reportingPeriods.filterToggle.tooltip")}>
                    <InfoCircleOutlined />
                </Tooltip>
                <Divider />
            </Row>

            {/* View/Select Dataset Groups */}
            <Row gutter={[16, 16]}>
                <Col flex="auto">
                    <Typography.Title level={4}>
                        {t("admin.program.reportingPeriods.table.title")}
                    </Typography.Title>
                </Col>
                <Col flex="100px">
                    <Button
                        ghost={true}
                        key="select-all"
                        loading={rawPrograms.loading || bulkEditReportingPeriods.inFlight}
                        onClick={toggleSelectAll}
                        title={selectAllButtonText}
                        type="primary"
                    >
                        {selectAllButtonText}
                    </Button>
                </Col>
            </Row>

            {/* Selection Error */}
            {error === ErrorEnum.NoProgramsSelected &&
                <Row style={{ paddingBottom: 16 }}>
                    <Col span={24}>
                        <Alert
                            type="error"
                            showIcon
                            closable
                            message={t("admin.program.reportingPeriods.form.validation.noProgramsSelected")}
                        />
                    </Col>
                </Row>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Table
                        dataSource={filteredData}
                        columns={columns}
                        rowSelection={rowSelection}
                        loading={rawPrograms.loading} />
                </Col>
            </Row>

            <Row justify="center" style={{ padding: 32 }}>
                {/* Save Button */}
                <Button
                    type="primary"
                    key="submit-bulk-edit"
                    onClick={validateInputsAndShowConfirmModal}
                    icon={<SaveOutlined />}
                    loading={rawPrograms.loading || bulkEditReportingPeriods.inFlight}
                    disabled={reportingPeriodType === ReportingPeriodType.custom}
                >
                    {t("admin.program.reportingPeriods.form.submit.button")}
                </Button>
                
                {/* Confirmation Modal */}
                <Modal
                    key="confirm-bulk-edit"
                    forceRender
                    open={showConfirmSubmission}
                    onOk={submitBulkEdit}
                    okText={t("admin.program.reportingPeriods.form.confirmSubmit.confirmButton")}
                    okButtonProps={{ 
                        loading: rawPrograms.loading || bulkEditReportingPeriods.inFlight,
                        htmlType: "submit", 
                        disabled: reportingPeriodType === ReportingPeriodType.custom 
                    }}
                    onCancel={() => setShowConfirmSubmission(false)}
                    cancelText={t("admin.program.reportingPeriods.form.confirmSubmit.cancelButton")}
                    title={t("admin.program.reportingPeriods.form.confirmSubmit.title")}
                >
                    <p>You have selected <b>{selectedProgramIds.length}</b> {selectedProgramIds.length > 1 ? "datasets" : "dataset"}.</p>
                    <p>Each selected dataset will receive a complete set of <b>{reportingPeriodType}</b> reporting periods for {reportingPeriodType === ReportingPeriodType.annual ? "the next annual cycle." : "both the current and next annual cycles."}</p>
                    <p>An annual cycle of <b>{reportingPeriodType}</b> reporting periods runs from <b>{reportingPeriodType === ReportingPeriodType.annual ? "1st April to 31st March" : "1st January to 31st December"}</b>, and consists of <b>{mapReportingPeriodTypeToNumberPerAnnualCycle[reportingPeriodType]}</b> individual reporting periods.</p>
                    <p>There are <b>{reportingPeriodsUntilEndOfNextAnnualCycle[reportingPeriodType].length}</b> individual <b>{reportingPeriodType}</b> reporting periods between now and the end of the next annual cycle.</p>
                </Modal>
            </Row>
        </>
    );
};