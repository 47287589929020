import { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";

import generateDivisionResultSummary from "./generateDivisionResultSummary";
import { 
    filterReportsDataByFilterState,
    getHighPerformersForCategories,
    getRoundedPercent, 
    groupPublishedRecords, 
    NO_DATA_TO_DISPLAY_SECTION, 
    ResultSummary, 
    ResultSummaryData, 
    ResultSummarySection,
    ResultSummarySectionType,
    stringIsNotUndefined
} from "./index";
import { FilterStateItem } from "../Reports";
import { IPublishedRecordSetDocument } from "../../DatasetDetails/PublishedRecordSet";
import { 
    GetReportsData_reportsData,
    GetReportsData_reportsData_publishedRecordSets,
    GetReportsData_reportsData_publishedRecordSets_dataset_program_department
} from "../../../graphql/__generated__/GetReportsData";
import { IChartData } from "../../../selectors/ChartData";
import { joinArrayStringItems } from "../../../utils/array";


type DepartmentResultSummaryCategoryStats = {
    categoryName: string;
    categoryValue: string;
    groupTargetPercent: number;
    numberOfDatasetsPublishing: number;
    numberOfDatasetsOnTarget: number;
    numberOfDatasetsWithin10Percent: number;
    numberOfTeamsImproved: number;
    datasetNamesExceededTarget: string[];
}

type DepartmentResultSummaryMultiCategoryHighlight = {
    datasetName: string;
    results: {
        categoryName: string;
        result: number;    
    }[];
}


const getFilterSelectionsAsText = (data: ResultSummaryData): string => {
    const filterSelections = [...data.filterState.departments, ...data.filterState.miscTags];
    return joinArrayStringItems(filterSelections.map(({ name }) => name));
};

const isPublishedRecordDocumentValid = (document: unknown, category: string): document is IPublishedRecordSetDocument => (
    !!document && 
    !!(document as IPublishedRecordSetDocument).record && 
    !!(document as IPublishedRecordSetDocument).record["Everyone"] &&
    !!(document as IPublishedRecordSetDocument).record["Everyone"][category] &&
    !!(document as IPublishedRecordSetDocument).record["Everyone"][category].entries
);

const categoryStatIsNotUndefined = (
    categoryStat: DepartmentResultSummaryCategoryStats | undefined
): categoryStat is DepartmentResultSummaryCategoryStats => categoryStat !== undefined;

const departmentIsNotUndefined = (
    department: GetReportsData_reportsData_publishedRecordSets_dataset_program_department | null | undefined
): department is GetReportsData_reportsData_publishedRecordSets_dataset_program_department => !!department;

const getDepartments = (
    departments: FilterStateItem[],
    reportsData: GetReportsData_reportsData,
) => {
    const concat = [
        ...reportsData.publishedRecordSets.map(published => published.dataset?.program?.department),
        ...reportsData.unpublishedDatasets.map(unpublished => unpublished.dataset.program?.department),
        ...reportsData.offAirDatasets.map(offair => offair.program?.department),
    ];
    
    return departments
        .map(({ id }) => concat.find(department => !!department && department?.id === id))
        .filter(departmentIsNotUndefined);
};

const getCategoryStats = (
    category: string,
    publishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
    prevPublishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
    currentGroupedPublishedRecords: Record<string, IChartData> | undefined,
): DepartmentResultSummaryCategoryStats | undefined => {
    const filteredPublishedRecords = publishedRecords.filter(publishedRecord => (
        !!(publishedRecord?.document as IPublishedRecordSetDocument)?.record?.["Everyone"]?.[category]
    ));

    if (filteredPublishedRecords.length > 0) {
        const categoryValue = Object.values(
            (filteredPublishedRecords[0]?.document as IPublishedRecordSetDocument)?.record?.["Everyone"]?.[category]?.entries
        ).find(entry => entry.targetMember === true)?.attribute || "";
        const groupTargetPercent = (filteredPublishedRecords[0].dataset?.program?.targets
            .find(target => target.category.name === category)?.target || 0) * 100;

        const categoryStats = currentGroupedPublishedRecords && category in currentGroupedPublishedRecords 
            ? currentGroupedPublishedRecords[category] : undefined;
        const numberOfDatasetsPublishing = categoryStats ? categoryStats.datasets.size : 0;
        const numberOfDatasetsOnTarget = categoryStats ? categoryStats.exceeds : 0;
        const numberOfDatasetsWithin10Percent = categoryStats ? categoryStats.lt10 + categoryStats.lt5 : 0;

        const filteredPrevPublishedRecords = prevPublishedRecords.filter(prevPublishedRecord => (
            filteredPublishedRecords.some(publishedRecord => (
                publishedRecord.dataset?.id === prevPublishedRecord.dataset?.id)
            ))
        );
        const improvedTeams = filteredPublishedRecords
            .filter(publishedRecord => {
                const matchingPrev = filteredPrevPublishedRecords.find(prevPublishedRecord => (
                    prevPublishedRecord.dataset?.id === publishedRecord.dataset?.id
                ));

                if (
                    matchingPrev && 
                    isPublishedRecordDocumentValid(publishedRecord.document, category) && 
                    isPublishedRecordDocumentValid(matchingPrev.document, category)
                ) {
                    const currentEntries = Object.values(
                        (publishedRecord.document as IPublishedRecordSetDocument).record["Everyone"][category].entries
                    ).filter(entry => entry.targetMember === true);
                    const prevEntries = Object.values(
                        (matchingPrev.document as IPublishedRecordSetDocument).record["Everyone"][category].entries
                    ).filter(entry => entry.targetMember === true);

                    const currentPercent = currentEntries.reduce((acc, curr) => acc + curr.percent, 0);
                    const prevPercent = prevEntries.reduce((acc, curr) => acc + curr.percent, 0);
                
                    return currentPercent > prevPercent;
                }
                return false;
            }).map(publishedRecord => publishedRecord.dataset?.program?.team?.id);
        const numberOfTeamsImproved = new Set(improvedTeams).size;

        const datasetNamesExceededTarget = filteredPublishedRecords
            .filter(publishedRecord => {
                const entries = Object.values(
                    (publishedRecord?.document as IPublishedRecordSetDocument)?.record?.["Everyone"]?.[category]?.entries
                ).filter(entry => entry.targetMember === true);
                const percent = entries.reduce((acc, curr) => acc + curr.percent, 0);
            
                const target = publishedRecord.dataset?.program?.targets.find(target => target.category.name === category);

                return target ? percent > (target.target * 100) : false;
            })
            .map(publishedRecord => publishedRecord.dataset?.name || "")
            .sort((a, b) => a.localeCompare(b));

        return {
            categoryName: category,
            categoryValue,
            groupTargetPercent,
            numberOfDatasetsPublishing,
            numberOfDatasetsOnTarget,
            numberOfDatasetsWithin10Percent,
            numberOfTeamsImproved,
            datasetNamesExceededTarget,
        };
    }
};

const getMultiCategoryHighlights = (
    publishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
): DepartmentResultSummaryMultiCategoryHighlight[] => {
    const filteredPublishedRecords = publishedRecords.filter(publishedRecord => (
        !!(publishedRecord?.document as IPublishedRecordSetDocument)?.record?.["Everyone"]
    ));

    const multiCategoryHighlights = filteredPublishedRecords.reduce((exceptionalRecords, publishedRecord) => {
        const datasetName = publishedRecord.dataset?.name || "";

        const results = Object.entries((publishedRecord?.document as IPublishedRecordSetDocument)?.record?.["Everyone"])
            .map(([categoryName, grouping]) => {
                const entries = Object.values(grouping.entries).filter(entry => entry.targetMember === true);
                const result = Math.round(entries.reduce((acc, curr) => acc + curr.percent, 0));
            
                const target = publishedRecord.dataset?.program?.targets.find(target => target.category.name === categoryName);

                return { categoryName, target, result };
            })
            .filter(entry => entry.target ? entry.result >= (entry.target.target * 100) : false)
            .map(({ categoryName, result }) => ({ categoryName, result }));

        return results.length > 1 && datasetName
            ? [ ...exceptionalRecords, { datasetName, results } ] : exceptionalRecords;
    }, [] as DepartmentResultSummaryMultiCategoryHighlight[]);

    return multiCategoryHighlights;
};

const addDepartmentSection = (data: ResultSummaryData, date: Dayjs, sections: ResultSummarySection[]): void => {
    const { categories, reportsData, tz } = data;
    const { publishedRecordSets, publishedRecordSetsPreviousOne } = reportsData.currentMonth;
    const currentGroupedPublishedRecords = groupPublishedRecords(categories, publishedRecordSets, tz);

    const focusedCategoryStats = [
        getCategoryStats(categories[0], publishedRecordSets, publishedRecordSetsPreviousOne, currentGroupedPublishedRecords)
    ].filter(categoryStatIsNotUndefined);
    const categoryStats = categories.slice(1).map(category => (
        getCategoryStats(category, publishedRecordSets, publishedRecordSetsPreviousOne, currentGroupedPublishedRecords))
    ).filter(categoryStatIsNotUndefined);

    const categoryHighlights = getHighPerformersForCategories(categories, publishedRecordSets);
    const multiCategoryHighlights = getMultiCategoryHighlights(publishedRecordSets);

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.NORMAL,
        content: [
            {
                id: uuidv4(),
                lines: focusedCategoryStats.map(focusedCategoryStat => {
                    const focusedCategoryPercentText = `${getRoundedPercent(focusedCategoryStat.numberOfDatasetsOnTarget, focusedCategoryStat.numberOfDatasetsPublishing)}%`;
                    const focusedCategoryProportionText = `${focusedCategoryStat.numberOfDatasetsOnTarget} out of ${focusedCategoryStat.numberOfDatasetsPublishing}`;
                    const smallDepartmentText = `${focusedCategoryProportionText} programme(s)`;
                    const largeDepartmentText = `${focusedCategoryPercentText} of programme(s) (${focusedCategoryProportionText})`;
    
                    const baseText = `${focusedCategoryStat.numberOfDatasetsPublishing > 20 ? largeDepartmentText : smallDepartmentText} who filed in ${date.format("MMMM")} reached at least ${focusedCategoryStat.groupTargetPercent}% ${focusedCategoryStat.categoryValue}`;
                    const extraText = focusedCategoryStat.numberOfDatasetsWithin10Percent > 0 ? `- with ${focusedCategoryStat.numberOfDatasetsWithin10Percent} other(s) achieving above ${focusedCategoryStat.groupTargetPercent - 10}%.` : ".";
                    
                    return { id: uuidv4(), text: baseText + extraText };
                })
            },
            {
                id: uuidv4(),
                lines: categoryStats.flatMap(categoryStat => {
                    const textStart = `${categoryStat.numberOfDatasetsPublishing} programme(s) were monitoring ${categoryStat.categoryName}`;
                    const textMiddleStart = `, ${categoryStat.numberOfDatasetsOnTarget} met their ${categoryStat.categoryName} targets`;
                    const textMiddleEnd = categoryStat.numberOfDatasetsWithin10Percent > 0 
                        ? ` - with ${categoryStat.numberOfDatasetsWithin10Percent} other(s) achieving within a 10% margin.`
                        : ".";
                    const textEnd = ` ${categoryStat.numberOfTeamsImproved} team(s) improved on ${categoryStat.categoryName} representation.`;
                    const text = categoryStat.numberOfDatasetsPublishing > 0 
                        ? (textStart + textMiddleStart + textMiddleEnd + textEnd) 
                        : (textStart + ".");
    
                    const lines = [{ id: uuidv4(), text }];
        
                    if (
                        categoryStat.datasetNamesExceededTarget.length > 0 && 
                        categoryStat.datasetNamesExceededTarget.length < 10
                    ) {
                        lines.push({
                            id: uuidv4(),
                            text: `The following programme(s) reached above their goal for ${categoryStat.categoryName}: ${joinArrayStringItems(categoryStat.datasetNamesExceededTarget)}.`
                        });
                    }
        
                    return lines;
                })
            },
            {
                id: uuidv4(),
                lines: categoryHighlights
                    .filter(highlight => highlight.result > 5)
                    .map(highlight => ({
                        id: uuidv4(),
                        text: `${joinArrayStringItems(highlight.datasetNames)} achieved the highest percentage of ${Math.round(highlight.result)}% in the ${highlight.categoryName} category.`,
                    }))
            },
            {
                id: uuidv4(),
                lines: multiCategoryHighlights.length > 5 
                    ? [{
                        id: uuidv4(),
                        text: `${multiCategoryHighlights.length} programme(s) achieved their target across 2 or more categories.`
                    }] 
                    : multiCategoryHighlights.map(highlight => {
                        const resultStrings = highlight.results.map(({categoryName, result}) => `${result}% ${categoryName}`);
                        return {
                            id: uuidv4(),
                            text: `${highlight.datasetName} achieved ${joinArrayStringItems(resultStrings)} representation.`,
                        };
                    }),
            },
        ],
    });
};

const addDivisionSections = (
    data: ResultSummaryData,
    date: Dayjs,
    sections: ResultSummarySection[]
): void => {
    const departments = getDepartments(data.filterState.departments, data.reportsData.currentMonth);
    [...new Map(departments.map(({ division }) => [division.id, { id: division.id, name: division.name }])).values()]
        .forEach(division => {
            const overwriteData: ResultSummaryData = { ...data, filterState: { ...data.filterState, divisions: [division] }};
            const summary = generateDivisionResultSummary(overwriteData, date);
            const summarySections = summary.sections.filter(section => section.type === ResultSummarySectionType.NORMAL);
            const divisionSections = [{ ...summarySections[0], header: summary.title }, ...summarySections.slice(1)];
            divisionSections.forEach(section => sections.push(section));
        });
};

const addAdminSection = (data: ResultSummaryData, date: Dayjs, sections: ResultSummarySection[]): void => {
    const { isAdmin, reportsData } = data;

    if (isAdmin) {
        const { publishedRecordSets, unpublishedDatasets } = reportsData.currentMonth;
        const publishedTeamIds = publishedRecordSets
            .map(published => published.dataset?.program?.team?.id)
            .filter(stringIsNotUndefined);
        const unpublishedTeamIds = unpublishedDatasets
            .map(unpublished => unpublished.dataset.program?.team?.id)
            .filter(stringIsNotUndefined);

        const numberOfTeamsUnpublished = new Set(unpublishedTeamIds).size;
        const numberOfTeamsShouldPublish = new Set([...publishedTeamIds, ...unpublishedTeamIds]).size;

        sections.push({
            id: uuidv4(),
            type: ResultSummarySectionType.ADMIN,
            header: "For Administrators only:",
            content: [{
                id: uuidv4(),
                lines: [{
                    id: uuidv4(),
                    text: `${numberOfTeamsUnpublished} of ${numberOfTeamsShouldPublish} team(s) who should have published their data didn't do so in ${date.format("MMMM")}.`,
                }]
            }],
        });
    }
};

export default (data: ResultSummaryData, date: Dayjs): ResultSummary => {
    const overwriteData: ResultSummaryData = { 
        ...data, 
        filterState: { ...data.filterState, divisions: [], programs: [], teams: [] }
    };
    const filteredData = filterReportsDataByFilterState(overwriteData);
    
    const filterSelectionsAsText = getFilterSelectionsAsText(filteredData);
    const title = `The 50:50 Project Department Results for ${date.format("MMMM YYYY")} - ${filterSelectionsAsText}`;
    
    const sections: ResultSummarySection[] = [];
    addDepartmentSection(filteredData, date, sections);
    addDivisionSections(data, date, sections);
    addAdminSection(filteredData, date, sections);

    if (sections.length === 0) {
        sections.push(NO_DATA_TO_DISPLAY_SECTION);
    }

    return { title, sections };
};
