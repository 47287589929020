import { Theme } from "@nivo/core";


export const getBarChartTheme = (overrideFontSize?: number): Theme => ({
    background: "#FFFFFF",
    text: {
        fontSize: overrideFontSize ? overrideFontSize : 14,
        fill: "#000000",
    },
    axis: {
        ticks: { line: { strokeWidth: "1.5px", stroke: "#777777" } },
        domain: { line: { strokeWidth: "1px", stroke: "#777777" } },
        legend: { text: { fontSize: overrideFontSize ? overrideFontSize : 14, fill: "#000000" } }
    },
    grid: {
        line: { stroke: "#DDDDDD", strokeWidth: "1px" },
    },
});