import { gql } from "@apollo/client";

export const ADMIN_GET_ALL_PROGRAMS = gql`
  query AdminGetAllPrograms {
    programs {
      id
      name
      deleted
      team {
        id
        name
      }
      datasets {
        id
        name
        lastUpdated
      }
      department {
        name
        division {
          name
        }
      }
      contentTypes {
        name
      }
      platforms {
        name
      }
      tags {
        name
      }
      targets {
          category {
            name
          }       
      }
    }
  }
`;
