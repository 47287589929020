import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { ApolloQueryResult, useQuery } from "@apollo/client";
import { Alert, Button, Card, Checkbox, Col, Divider, Form, Input, message, Popconfirm, Row, Select, Space, Tag } from "antd";
import { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { AdminGetAllRoles, AdminGetAllRoles_roles } from "../../../graphql/__generated__/AdminGetAllRoles";
import { AdminGetAllTeams, AdminGetAllTeams_teams } from "../../../graphql/__generated__/AdminGetAllTeams";
import { 
    AdminGetUser,
    AdminGetUser_user,
    AdminGetUser_user_teams,
    AdminGetUser_user_teams_programs,
    AdminGetUser_user_teams_programs_department
} from "../../../graphql/__generated__/AdminGetUser";
import { ADMIN_GET_ALL_ROLES } from "../../../graphql/__queries__/AdminGetAllRoles.gql";
import { ADMIN_GET_ALL_TEAMS } from "../../../graphql/__queries__/AdminGetAllTeams.gql";
import { ADMIN_GET_USER } from "../../../graphql/__queries__/AdminGetUser.gql";
import { useUserAccountManager } from "../../../hooks/UserAccountManagerProvider";
import { isUserRoleWithPermissions } from "../../../typeguards";
import { capitaliseFirstLetterOfEachWord, forceStringLowercase, forceStringUppercase } from "../../../utils/string";


type EditUserForm = {
    email: string;
    firstName: string;
    lastName: string;
    permissions?: string[];
    roles?: string[];
    teams?: string[];
    username: string;
};

type ProgramWithNonNullDepartment = 
    AdminGetUser_user_teams_programs
    & { readonly department: AdminGetUser_user_teams_programs_department };

type UserInputCardProps = {
    data: {
        roles: readonly AdminGetAllRoles_roles[];
        teams: readonly AdminGetAllTeams_teams[];
        user: AdminGetUser_user | undefined;
    };
    t: TFunction<"translation", undefined>;
}


const filterOutProgramsWithNullDepartment = (
    program: AdminGetUser_user_teams_programs
): program is ProgramWithNonNullDepartment => program.department !== null;

const filterOutDuplicateStrings = (value: string, index: number, array: string[]) => array.indexOf(value) === index;

const mapDivisionsAndDepartments = (teams: readonly AdminGetUser_user_teams[]) => {
    const filteredDepartments = teams
        .flatMap(team => team.programs.filter(filterOutProgramsWithNullDepartment))
        .map(program => program.department);

    const departments = filteredDepartments
        .map(department => department.name)
        .filter(filterOutDuplicateStrings)
        .sort((a, b) => a.localeCompare(b));
    const divisions = filteredDepartments
        .map(department => department.division.name)
        .filter(filterOutDuplicateStrings)
        .sort((a, b) => a.localeCompare(b));

    return { departments, divisions };
};

const mapRolesAndPermissions = (roles: readonly AdminGetAllRoles_roles[]) => ({
    permissions: roles.filter(({ name }) => isUserRoleWithPermissions(name)),
    roles: roles.filter(({ name }) => !isUserRoleWithPermissions(name)),
});

const UserInputCard = ({ data, t }: UserInputCardProps) => {
    const { roles: rawRoles, teams, user } = data;

    const { departments, divisions } = mapDivisionsAndDepartments(user?.teams || []);
    const { permissions, roles } = mapRolesAndPermissions(rawRoles);

    return (
        <Card style={{ marginBottom: 16 }}>
            <Row>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.firstName.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="firstName"
                        rules={[{ required: true, message: t("admin.user.edit.inputs.firstName.required") }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={t("admin.user.edit.inputs.firstName.placeholder")}
                            title={t("admin.user.edit.inputs.firstName.label")}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider style={{ height: "100%", marginLeft: 20 }} type="vertical"/>
                </Col>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.lastName.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="lastName"
                        rules={[{ required: true, message: t("admin.user.edit.inputs.lastName.required") }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={t("admin.user.edit.inputs.lastName.placeholder")}
                            title={t("admin.user.edit.inputs.lastName.label")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.username.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="username"
                        rules={[{ required: true, message: t("admin.user.edit.inputs.username.required") }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={t("admin.user.edit.inputs.username.placeholder")}
                            title={t("admin.user.edit.inputs.username.label")}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider style={{ height: "100%", marginLeft: 20 }} type="vertical"/>
                </Col>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.email.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="email"
                        rules={[{ required: true, message: t("admin.user.edit.inputs.email.required") }]}
                        validateTrigger="onBlur"
                    >
                        <Input
                            placeholder={t("admin.user.edit.inputs.email.placeholder")}
                            title={t("admin.user.edit.inputs.email.label")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item 
                        label={t("admin.user.edit.inputs.permissions.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="permissions"
                    >
                        <Checkbox.Group>
                            {
                                permissions
                                    .sort((a, b) => a.name.localeCompare(b.name) * -1)
                                    .map(({ id, name }) => (
                                        <Checkbox 
                                            key={`role-checkbox-${id}`} 
                                            title={capitaliseFirstLetterOfEachWord(name)} 
                                            value={id}
                                        >
                                            {capitaliseFirstLetterOfEachWord(name)}
                                        </Checkbox>
                                    ))
                            }
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider style={{ height: "100%", marginLeft: 20 }} type="vertical"/>
                </Col>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.roles.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="roles"
                        shouldUpdate
                    >
                        <Select
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            filterSort={(a, b) => a.label.localeCompare(b.label)}
                            mode="multiple"
                            options={roles.map(({ id, name }) => ({ label: name, value: id }))}
                            placeholder={t("admin.user.edit.inputs.roles.placeholder")}
                            title={t("admin.user.edit.inputs.roles.label")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <Form.Item
                        label={t("admin.user.edit.inputs.teams.label")}
                        labelAlign="right"
                        labelCol={{ span: 5 }}
                        name="teams"
                        shouldUpdate
                    >
                        <Select
                            filterOption={(input, option) => (
                                option?.label ?? "").toLowerCase().includes(input.toLowerCase()
                            )}
                            filterSort={(a, b) => a.label.localeCompare(b.label)}
                            mode="multiple"
                            options={teams.map(({ id, name }) => ({ label: name, value: id }))}
                            placeholder={t("admin.user.edit.inputs.teams.placeholder")}
                            title={t("admin.user.edit.inputs.teams.label")}
                        />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Divider style={{ height: "100%", marginLeft: 20 }} type="vertical"/>
                </Col>
                <Col span={11}>
                    <Row>
                        <Col span={24}>
                            {
                                departments.length > 0 &&
                                <Form.Item 
                                    label={t("admin.user.edit.inputs.departments.label")}
                                    labelAlign="right"
                                    labelCol={{ span: 5 }}
                                >
                                    {departments.map(item => <Tag key={`department-${item}`}>{item}</Tag>)}
                                </Form.Item>
                            }
                        </Col>
                        <Col span={24}>
                            {
                                divisions.length > 0 &&
                                <Form.Item
                                    label={t("admin.user.edit.inputs.divisions.label")}
                                    labelAlign="right"
                                    labelCol={{ span: 5 }}
                                >
                                    {divisions.map(item => <Tag key={`division-${item}`}>{item}</Tag>)}
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

const validateResponse = <T extends AdminGetAllRoles | AdminGetAllTeams | AdminGetUser>(
    response: ApolloQueryResult<T | undefined>,
    expectedKey: keyof T,
    t: TFunction
) => {
    if (response.error) {
        return response.error;
    }

    if (!response.data) {
        return new Error(t("admin.user.edit.queries.errors.noData"));
    }

    if (!response.data[expectedKey]) {
        return new Error(t("admin.user.edit.queries.errors.missingKey"));
    }

    return null;
};

const useAdminUserQueries = (id: string, t: TFunction) => {
    const rolesResult = useQuery<AdminGetAllRoles>(ADMIN_GET_ALL_ROLES);
    const teamsResult = useQuery<AdminGetAllTeams>(ADMIN_GET_ALL_TEAMS, { fetchPolicy: "network-only" });
    const userResult = useQuery<AdminGetUser>(ADMIN_GET_USER, { variables: { id }, fetchPolicy: "network-only" });

    const rolesError = validateResponse(rolesResult, "roles", t);
    const teamsError = validateResponse(teamsResult, "teams", t);
    const userError = validateResponse(userResult, "user", t);

    return {
        error: rolesError || teamsError || userError,
        loading: rolesResult.loading || teamsResult.loading || userResult.loading,
        roles: rolesResult.data?.roles || [],
        teams: teamsResult.data?.teams || [],
        user: userResult.data?.user,
        refresh: () => {
            rolesResult.refetch();
            teamsResult.refetch();
            userResult.refetch();
        },
    };
};

const useSaveUser = (userId: string, t: TFunction, refresh: () => void) => {
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState<Error | null>(null);
    const userAccount = useUserAccountManager();

    const saveUser = async (initialValues: EditUserForm, values: EditUserForm) => {
        setSaveError(null);
        setSaving(true);

        try {
            await userAccount.edit(userId, {
                first_name: values.firstName,
                last_name: values.lastName,
                ...(values.email !== initialValues.email && { email: forceStringLowercase(values.email) }),
                ...(values.username !== initialValues.username && { username: forceStringUppercase(values.username) }),
                roles: (values.permissions || []).concat(values.roles || []),
                teams: values.teams,
            });

            message.success(t("admin.user.edit.actions.save.success"));
            refresh();
            return true;
        } catch (err: unknown) {
            const error = err instanceof Error ? err : new Error("UNKNOWN_ERROR");
            setSaveError(error);
            return false;
        } finally {
            setSaving(false);
        }
    };

    return { saving, saveError, saveUser };
};

const useDeleteUser = (userId: string, refresh: () => void) => {
    const account = useUserAccountManager();
    const [deleting, setDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState<Error | null>(null);

    return {
        deleting,
        deleteError,
        deleteUser: async () => {
            setDeleting(true);
            setDeleteError(null);
            try {
                await account.deleteUser(userId);
                refresh();
            } catch (e: unknown) {
                if (e instanceof Error) return setDeleteError(e);
            } finally {
                setDeleting(false);
            }
        },
    };
};

const useRestoreUser = (userId: string, refresh: () => void) => {
    const account = useUserAccountManager();
    const [restoring, setRestoring] = useState(false);
    const [restoreError, setRestoreError] = useState<Error | null>(null);

    return {
        restoring,
        restoreError,
        restoreUser: async () => {
            setRestoring(true);
            setRestoreError(null);
            try {
                await account.restoreUser(userId);
                refresh();
            } catch (e: unknown) {
                if (e instanceof Error) return setRestoreError(e);
            } finally {
                setRestoring(false);
            }
        },
    };
};


export const EditUser = () => {
    const { userId } = useParams() as { userId: string };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [editUserForm] = Form.useForm<EditUserForm>();

    const { error, loading, roles, teams, user, refresh } = useAdminUserQueries(userId, t);

    const { saving, saveError, saveUser } = useSaveUser(userId, t, refresh);
    const { deleting, deleteError, deleteUser } = useDeleteUser(userId, refresh);
    const { restoreError, restoreUser } = useRestoreUser(userId, refresh);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        throw error;
    }

    const initialValues: EditUserForm = {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        username: user?.username || "",
        email: user?.email || "",
        permissions: user?.roles.filter(({ name }) => isUserRoleWithPermissions(name)).map(({ id }) => id),
        roles: user?.roles.filter(({ name }) => !isUserRoleWithPermissions(name)).map(({ id }) => id),
        teams: user?.teams.map(({ id }) => id),
    };

    const hasUserBeenDeleted = !user?.active;

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={t("admin.user.edit.title")}/>

            {/* Page Header */}
            <PageHeader onBack={() => navigate("/admin/users")} title={t("admin.user.edit.title")}/>
            
            {/* Banner */}
            {hasUserBeenDeleted && (
                <Alert
                    action={
                        <Button danger onClick={restoreUser} type="primary">
                            {t("admin.user.edit.actions.restore.title")}
                        </Button>
                    }
                    description={t("admin.user.edit.actions.restore.description")}
                    message={t("admin.user.edit.actions.restore.message")}
                    showIcon
                    style={{ marginBottom: 16 }}
                    type="warning"
                />
            )}

            {/* Errors */}
            {[
                { action: "save", error: saveError },
                { action: "delete", error: deleteError },
                { action: "restore", error: restoreError },
            ]
                .filter((e) => !!e.error)
                .map(({ action, error }) => (
                    <Alert
                        closable
                        description={error?.message}
                        key={action}
                        message={t(`admin.user.edit.actions.${action}.error`)}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                ))}

            {/* Edit User Form */}
            <Form
                form={editUserForm}
                initialValues={initialValues}
                onFinish={async (values) => await saveUser(initialValues, values)}
                scrollToFirstError
            >
                {/* Input Fields */}
                <Row>
                    <Col offset={1} span={22}>
                        <UserInputCard data={{ roles, teams, user }} t={t} />
                    </Col>
                </Row>
                <Row justify="center">
                    <Form.Item>
                        <Space>
                            {/* Save Button */}
                            <Button
                                disabled={deleting || hasUserBeenDeleted}
                                htmlType="submit"
                                icon={<SaveOutlined />}
                                loading={saving}
                                title={t("admin.user.edit.actions.save.title")}
                                type="primary"
                            >
                                {t("admin.user.edit.actions.save.title")}
                            </Button>

                            {/* Delete Button */}
                            <Popconfirm
                                title={t("admin.user.edit.actions.delete.title")}
                                description={t("admin.user.edit.actions.delete.description")}
                                onConfirm={deleteUser}
                                disabled={saving || hasUserBeenDeleted}
                                okText={t("admin.user.edit.actions.delete.confirmText")}
                                cancelText={t("admin.user.edit.actions.delete.cancelText")}
                            >
                                <Button
                                    danger
                                    icon={<DeleteOutlined />}
                                    disabled={saving || hasUserBeenDeleted}
                                    loading={deleting}
                                >
                                    {t("admin.user.edit.actions.delete.title")}
                                </Button>
                            </Popconfirm>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
};
