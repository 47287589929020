import { PictureOutlined } from "@ant-design/icons";
import { BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Row } from "antd";
import { Dayjs } from "dayjs";
import { useState } from "react";

import { getBarChartTheme } from "./styling";
import { FilterState } from "../Reports";
import { IChartData } from "../../../selectors/ChartData";
import { joinArrayStringItems } from "../../../utils/array";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";


type BarChartProps = {
    barChartData: BarChartDatum[];
    barChartKeys: Set<string>;
}

type BarChartDatum = BarChartDatumPartial & {
    date: string;
}

type BarChartDatumPartial = {
    [key: string]: number | string;
}

type DatasetsOnTargetBarChartProps = {
    data: Record<string, Record<string, IChartData>>;
    filterState: FilterState;
}


const CATEGORY_COLORS = {
    "Gender": "#CA1619",
    "Ethnicity": "#734695",
    "Disability": "#C36412",
    "SED": "#85420F",
    "Default": "rgba(255,51,0,1)",
};


const getBarChartProps = (data: Record<string, Record<string, IChartData>>): BarChartProps => {
    const barChartKeys = new Set<string>();
    const barChartData = Object.entries(data).map(([date, groupedByCategory]) => {
        const categoryValues = Object.fromEntries(
            Object.entries(groupedByCategory).map(([category, data]) => {
                barChartKeys.add(category);
                return [category, Math.round((data.exceeds / data.count) * 100)];
            })
        );

        return { date, ...categoryValues };
    });

    return { barChartData, barChartKeys };
};

const calculateFontSize = (numberOfMonths: number): number => {
    let fontSize = 14;
    if (numberOfMonths >= 9) {
        fontSize = 12;
        if (numberOfMonths >= 12) {
            fontSize = 10;
            if (numberOfMonths >= 15) {
                fontSize = 8;
            }
        }
    }
    return fontSize;
};

const isDateSelectionSingleMonth = (start: Dayjs, end: Dayjs): boolean => start.month() === end.month();

const getSectionDescription = (filterState: FilterState): JSX.Element => {    
    const { categories, contentTypes, departments, divisions, miscTags, platforms, programs, range, teams } = filterState;
    const { begin, end } = range;

    const filters = [
        ...divisions.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...departments.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...miscTags.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...programs.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...categories.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...contentTypes.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...platforms.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
        ...teams.map(({ name }) => name).sort((a,b) => a.localeCompare(b)),
    ].filter(name => !!name);

    const baseDescription = <>Proportion of datasets on target</>;
    const dateFormat = "MMMM YYYY";
    const dateDescription = isDateSelectionSingleMonth(begin, end) 
        ? <>in <b>{begin.format(dateFormat)}</b></>
        : <>between <b>{begin.format(dateFormat)}</b> and <b>{end.format(dateFormat)}</b></>;

    let filterDescription = <>for the whole BBC.</>;
    if (filters.length > 0) {
        filterDescription = filters.length > 5 ? 
            <>for <b>{joinArrayStringItems(filters.slice(0, 4))}</b> plus <b>{filters.slice(5).length}</b> more.</> : 
            <>for <b>{joinArrayStringItems(filters)}</b>.</>;
    }

    return <>{baseDescription} {dateDescription} {filterDescription}</>;
};

const calculateContainerWidth = (numberOfMonths: number): number => {
    let containerWidth = 25;
    for (let i = 1; i < numberOfMonths; i++) {
        if (containerWidth === 100) {
            break;
        } else {
            containerWidth += 25;
        }
    }

    return containerWidth;
};


const BarChartTooltip = ({ id, formattedValue, color }: BarTooltipProps<BarChartDatum>): JSX.Element => (
    <BasicTooltip
        id={id}
        value={`${formattedValue}%`}
        enableChip={true}
        color={color}
    />
);


export const DatasetsOnTargetBarChart = ({ data, filterState }: DatasetsOnTargetBarChartProps) => {
    const id = "datasets-on-target-bar-chart";
    const containerId = `${id}-container`;
    const imageFormats: ImageFormat[] = [ImageFormat.PNG, ImageFormat.JPEG];

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const { barChartData, barChartKeys } = getBarChartProps(data);
    const overrideFontSize = calculateFontSize(barChartData.length);
    const theme = getBarChartTheme(overrideFontSize);

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    return (
        <Row gutter={[16, 16]} style={{ marginBottom: 10 }} id={id}>
            <Col span={18}>
                <p>{getSectionDescription(filterState)}</p>
            </Col>
            <Col span={6} style={{ display: "flex", justifyContent: "end" }}>
                {
                    imageFormats.map(format => (
                        <Button
                            key={`download-${id}-${format}`}
                            disabled={imageLoading}
                            icon={<PictureOutlined />}
                            loading={imageLoading}
                            onClick={async () => await onClickDownloadImage(format)}
                            style={{ marginLeft: 10, marginBottom: 10 }}
                        >
                            {`.${format}`}
                        </Button>
                    ))
                }
            </Col>
            <Col span={24}>
                <div
                    id={containerId}
                    style={{ height: 300, width: `${calculateContainerWidth(barChartData.length)}%` }}
                >
                    <ResponsiveBar
                        // Base 
                        data={barChartData}
                        indexBy="date"
                        keys={[...barChartKeys]}
                        groupMode="grouped"
                        layout="vertical"
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        minValue={0}
                        maxValue={100}
                        margin={{ top: 10, bottom: 40, left: 60, right: 100 }}
                        // Style
                        theme={theme}
                        colors={(bar) => bar.id in CATEGORY_COLORS ? CATEGORY_COLORS[bar.id] : CATEGORY_COLORS["Default"]}
                        colorBy="id"
                        borderColor={{ from: "color", modifiers: [["darker", 1]] }}
                        borderWidth={1}
                        // Labels
                        enableLabel
                        label={(datum) => `${datum.value}%`}
                        labelSkipHeight={1}
                        labelTextColor="#FFFFFF"
                        // Grid & Axes
                        enableGridY
                        gridYValues={[0, 20, 40, 60, 80, 100]}
                        axisBottom={{ tickSize: 5, tickPadding: 5 }}
                        axisLeft={{ 
                            tickSize: 5,
                            tickPadding: 5,
                            tickValues: [0, 20, 40, 60, 80, 100],
                            legend: "% Datasets on Target",
                            legendPosition: "middle",
                            legendOffset: -45
                        }}
                        // Interactivity
                        tooltip={BarChartTooltip}
                        // Legends
                        legends={[{
                            anchor: "top-right",
                            dataFrom: "keys",
                            direction: "column",
                            justify: false,
                            itemDirection: "left-to-right",
                            itemHeight: 20,
                            itemsSpacing: 0,
                            itemWidth: 50,
                            symbolSize: 15,
                            translateX: 60,
                        }]}
                    />
                </div>
            </Col>
        </Row>
    );
};
