import { PlusOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Col, Form, FormListFieldData, FormListOperation, Input, Popconfirm, Row, Space } from "antd";
import { useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreateUserRoles, useDeleteUserRoles, useUpdateUserRoles } from "../../../hooks/programHooks";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetAllRoles, AdminGetAllRoles_roles } from "../../../graphql/__generated__/AdminGetAllRoles";
import { CreateUserRoleInput } from "../../../graphql/__generated__/globalTypes";
import { ADMIN_GET_ALL_ROLES } from "../../../graphql/__queries__/AdminGetAllRoles.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";


enum UserRoleWithPermissions { 
    admin = "admin",
    publisher = "publisher",
}

type UserRole = AdminGetAllRoles_roles | CreateUserRoleInput;

type EditUserRolesForm = {
    roles: UserRole[];
}

type UserRoleInputProps = {
    field: FormListFieldData;
    operations: FormListOperation;
    t: TFunction<"translation", undefined>;
};


const UserRoleInput = ({ field, operations, t }: UserRoleInputProps) => {
    const { name, ...restField } = field;

    const validateRoleNameLength = async (value: string): Promise<void | Error> => {
        if (value.length > 30) {
            return Promise.reject(new Error(t("admin.user.roles.input.errors.tooLong")));
        }
        return Promise.resolve();
    };

    const validateRoleNameChars = async (value: string): Promise<void | Error> => {
        const inputRegex = new RegExp("^[a-zA-Z0-9&\\s]*$");

        if (!inputRegex.test(value)) {
            return Promise.reject(new Error(t("admin.user.roles.input.errors.invalidCharacters")));
        }

        return Promise.resolve();
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={12}>
                <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    rules={[
                        { required: true, message: t("admin.user.roles.input.required")},
                        { validator: (_, value: string) => validateRoleNameLength(value) },
                        { validator: (_, value: string) => validateRoleNameChars(value)},
                    ]}
                    validateFirst
                    validateTrigger="onBlur"
                >
                    <Input placeholder={t("admin.user.roles.input.placeholder")} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Popconfirm
                    title={t("admin.user.roles.actions.delete.title")}
                    description={t("admin.user.roles.actions.delete.confirm.description")}
                    onConfirm={() => operations.remove(name)}
                    okText={t("admin.user.roles.actions.delete.confirm.yes")}
                    cancelText={t("admin.user.roles.actions.delete.confirm.no")}
                >
                    <Button danger>{t("admin.user.roles.actions.delete.title")}</Button>
                </Popconfirm>
            </Col>
        </Row>
    );
};

const ErrorBlock = ({ message }: { message: string }) => (
    <Row style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Col offset={1} span={22}>
            <Alert closable message={message} showIcon type="error"/>
        </Col>
    </Row>
);

const useAdminUserRoleQueries = () => {
    const rolesResponse = useQueryWithErrorHandling<AdminGetAllRoles>(
        ADMIN_GET_ALL_ROLES, "roles", { fetchPolicy: "network-only" }
    );

    return {
        loading: rolesResponse.loading,
        roles: rolesResponse.data?.roles || [],
        refresh: () => rolesResponse.refetch(),
    };
};

const isUserRoleWithPermissions = (name: UserRoleWithPermissions | string): name is UserRoleWithPermissions => (
    name === "admin" || name === "publisher"
);
const isCreateUserRoleInput = (role: AdminGetAllRoles_roles | CreateUserRoleInput): role is CreateUserRoleInput => (
    !!(role as CreateUserRoleInput).name && !(role as AdminGetAllRoles_roles).id
);
const isAdminGetAllRoles_roles = (role: AdminGetAllRoles_roles | CreateUserRoleInput): role is AdminGetAllRoles_roles => (
    !!(role as AdminGetAllRoles_roles).id && !!(role as AdminGetAllRoles_roles).name
);


export const ManageUserRoles = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [editUserRolesForm] = Form.useForm<EditUserRolesForm>();
    
    const { loading, roles, refresh } = useAdminUserRoleQueries();
    const createUserRoles = useCreateUserRoles();
    const updateUserRoles = useUpdateUserRoles();
    const deleteUserRoles = useDeleteUserRoles();

    const initialValues = useMemo(() => (
        { roles: roles.filter(({ name }) => !isUserRoleWithPermissions(name)) }
    ), [roles]);

    useEffect(() => { 
        editUserRolesForm.resetFields();
    }, [editUserRolesForm, roles]);
    
    const saving = createUserRoles.inFlight || deleteUserRoles.inFlight || updateUserRoles.inFlight;

    const onFinish = async (values: EditUserRolesForm) => {
        const preExistingUserRoles = values.roles.filter((role): role is AdminGetAllRoles_roles => isAdminGetAllRoles_roles(role));
        const userRolesToCreate = values.roles.filter((role): role is CreateUserRoleInput => isCreateUserRoleInput(role));
        const userRolesToDelete = initialValues.roles
            .filter(({ id: initialId }) => !preExistingUserRoles.some(({ id: submitId }) => initialId === submitId))
            .map(({ id }) => ({ id }));
        const userRolesToUpdate = preExistingUserRoles
            .filter(({ id, name }) => initialValues.roles.find((role) => role.id === id)?.name !== name)
            .map(({ id, name, description }) => ({ id, name, description }));
    
        if (userRolesToDelete.length > 0) {
            await deleteUserRoles.run({ roles: userRolesToDelete });
        }
        if (userRolesToUpdate.length > 0) {
            await updateUserRoles.run({ roles: userRolesToUpdate });
        }
        if (userRolesToCreate.length > 0) {
            await createUserRoles.run({ roles: userRolesToCreate });
        }

        refresh();
        return;
    };

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={t("admin.user.roles.title")} />

            {/* Page Header */}
            <PageHeader
                onBack={() => navigate("/")}
                title={t("admin.user.roles.title")}
                subTitle={t("admin.user.roles.subtitle")}
            />

            {/* Edit User Roles Form */}
            <Form
                autoComplete="off"
                form={editUserRolesForm}
                initialValues={initialValues}
                onFinish={onFinish}
            >
                {/* Errors */}
                {createUserRoles.error && <ErrorBlock message={createUserRoles.error.message} />}
                {updateUserRoles.error && <ErrorBlock message={updateUserRoles.error.message} />}
                {deleteUserRoles.error && <ErrorBlock message={deleteUserRoles.error.message} />}

                {/* User Roles */}
                <Form.List name="roles">
                    {(fields, operations) =>
                        <>
                            <Row style={{ paddingTop: 16 }}>
                                <Col offset={1} span={22}>
                                    {fields.map((field) => (
                                        <UserRoleInput 
                                            key={field.key}
                                            field={field}
                                            operations={operations}
                                            t={t}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 16 }}>
                                <Col offset={1} span={22}>
                                    <Form.Item>
                                        <Button 
                                            block
                                            icon={<PlusOutlined />}
                                            loading={loading || saving}
                                            onClick={() => operations.add()} 
                                            type="dashed" 
                                        >
                                            {t("admin.user.roles.actions.add")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                </Form.List>

                <Row gutter={[16, 16]} align="middle" justify="center" style={{ paddingTop: 16 }}>
                    <Form.Item>
                        <Space>
                            {/* Save Button */}
                            <Button 
                                htmlType="submit"
                                loading={loading || saving}
                                title={t("admin.user.roles.actions.save")}
                                type="primary" 
                            >
                                {t("admin.user.roles.actions.save")}
                            </Button>
                            
                            {/* Cancel Button */}
                            <Button
                                loading={loading || saving}
                                onClick={() => navigate("/")}
                                title={t("admin.user.roles.actions.cancel")}
                            >
                                {t("admin.user.roles.actions.cancel")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
};