import { PictureOutlined } from "@ant-design/icons";
import { PieCustomLayerProps, PieTooltipProps, ResponsivePie } from "@nivo/pie";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Row } from "antd";
import { useState } from "react";

import { FilterState } from "../Reports";
import { ICategoryGroup } from "../../../selectors/ChartData";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";


enum InOutTarget {
    IN_TARGET,
    OUT_OF_TARGET
}

type CircleXYCoords = {
    x: number;
    y: number;
}

type OverallPercentagePieChartProps = {
    category: string;
    data: Record<string, ICategoryGroup>;
    filterState: FilterState;
}

type PieChartDatum = {
    id: string;
    color: string;
    label: string;
    textColor: string;
    value: number;
    goal?: number;
};

type PieChartHeading = {
    numberOfDatasets: number;
    numberOfTeams: number;
}


const DEGREES_PER_PERCENT = 360 / 100;
const PERCENTAGE_THRESHOLD = 6.5;


const getPieChartHeading = (category: string, data: Record<string, ICategoryGroup>): PieChartHeading => ({
    numberOfDatasets: category in data ? data[category].datasets.size : 0,
    numberOfTeams: category in data ? data[category].teams.size : 0,
});

const getPieChartDatum = (category: string, value: number, isTarget: boolean): PieChartDatum => {
    switch (category) {
    case "Gender": {
        const id = isTarget ? "Women": "Men, non-binary";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        const color = isTarget ? "#CA1619": "#A5C3E5";
        const textColor = isTarget ? "#FFFFFF": "#000000";

        return { id, color, label, textColor, value, goal: 50 };
    }
    case "Ethnicity": {
        const id = isTarget ? "B.A.M.E." : "White";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        const color = isTarget ? "#734695" : "#58BCBE";
        const textColor = isTarget ? "#FFFFFF" : "#000000";

        return { id, color, label, textColor, value, goal: 20 };
    }
    case "Disability": {
        const id = isTarget ? "Disabled" : "Non-disabled";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        const color = isTarget ? "#C36412" : "#FCE53C";
        const textColor = isTarget ? "#FFFFFF" : "#000000";

        return { id, color, label, textColor, value, goal: 12 };
    }
    case "SED": {
        const id = isTarget ? "In Target" : "Out of Target";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        const color = isTarget ? "#85420F" : "#FFB8FF";
        const textColor = isTarget ? "#FFFFFF" : "#000000";

        return { id, color, label, textColor, value };
    }
    default: {
        const id = isTarget ? "In Target" : "Out of Target";
        const label = value >= PERCENTAGE_THRESHOLD ? id : `${id}; ${Math.round(value)}%`;
        const color = isTarget ? "rgba(255,51,0,1)" : "#FFFFFF";
        const textColor = isTarget ? "rgba(46,117,182,1)" : "#000000";

        return { id, color, label, textColor, value };
    }}
};

const getPieChartData = (category: string, data: Record<string, ICategoryGroup>): PieChartDatum[] => {
    const pieChartData: PieChartDatum[] = [];

    if (category in data) {
        [InOutTarget.IN_TARGET, InOutTarget.OUT_OF_TARGET].forEach(type => {
            const isInTarget = type === InOutTarget.IN_TARGET;

            const value = isInTarget ? data[category].percent : 100 - data[category].percent;
            const pieChartDatum = getPieChartDatum(category, value, isInTarget);
            
            pieChartData.push(pieChartDatum);
        });
    }
    
    return pieChartData;
};

const calculcateCircleXYCoords = (
    radius: number, theta: number, adjustX: number, adjustY: number
): CircleXYCoords => {
    const adjustedTheta = (theta - 90) * (Math.PI / 180);

    return {
        x: radius * Math.cos(adjustedTheta) + adjustX,
        y: (radius * Math.sin(adjustedTheta)) + adjustY,
    };
};


const PieChartGoalLine = ({ dataWithArc, centerX, centerY, radius, innerRadius }: PieCustomLayerProps<PieChartDatum>): JSX.Element | null => {
    const goal = dataWithArc[0]?.data?.goal;

    if (goal) {
        const theta = DEGREES_PER_PERCENT * goal;
        const innerLineCoords = calculcateCircleXYCoords((innerRadius - 5), theta, centerX, centerY);
        const outerLineCoords = calculcateCircleXYCoords((radius + 10), theta, centerX, centerY);
        const textCoords = calculcateCircleXYCoords((radius + 12), theta, centerX, centerY);
    
        return (
            <>
                {
                    goal &&
                    <>
                        <line
                            x1={innerLineCoords.x}
                            y1={innerLineCoords.y}
                            x2={outerLineCoords.x}
                            y2={outerLineCoords.y}
                            style={{ stroke: "grey", strokeWidth: 1.5 }}
                        />
                        <text
                            x={textCoords.x}
                            y={textCoords.y}
                            dominantBaseline={theta === 180 ? "hanging" : "auto"}
                            style={{ fill: "grey", fontSize: 12, fontFamily: "sans-serif" }}
                        >
                            {goal}%
                        </text>
                    </>
                }
            </>
        );
    } else {
        return null;
    }
};

const PieChartTooltip = ({ datum }: PieTooltipProps<PieChartDatum>): JSX.Element => (
    <BasicTooltip
        id={datum.id}
        value={`${datum.formattedValue}%`}
        enableChip={true}
        color={datum.color}
    />
);


export const OverallPercentagePieChart = ({ category, data, filterState }: OverallPercentagePieChartProps) => {
    const id = `${category}-overall-percentage-pie-chart`;
    const containerId = `${id}-container`;
    const imageFormats: ImageFormat[] = [ImageFormat.PNG, ImageFormat.JPEG];

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const pieChartHeading = getPieChartHeading(category, data);

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    return (
        <Row align="top">
            <Col span={20}>
                <div style={{ display: "grid", justifyContent: "center", textAlign: "center", marginLeft: 15 }}>
                    <div>{pieChartHeading.numberOfDatasets} datasets</div>
                    <div>{pieChartHeading.numberOfTeams} teams</div>
                </div>
                <div id={containerId} style={{ height: 230, width: "100%" }}>
                    <ResponsivePie
                        // Base
                        data={getPieChartData(category, data)}
                        id="id"
                        value="value"
                        valueFormat=">-d"
                        margin={{ top: 25, right: 0, bottom: 25, left: 25 }}
                        innerRadius={0.4}
                        cornerRadius={3}
                        // Style
                        theme={{
                            background: "#FFFFFF",
                            text: { fontSize: 14, fill: "#000000" }
                        }}
                        colors={(datum) => datum.data.color}
                        borderWidth={1}
                        borderColor={{ 
                            from: "color",
                            modifiers: [[ "darker", 0.8 ]]
                        }}
                        // Arc Labels
                        enableArcLabels
                        arcLabel={(datum) => `${datum.formattedValue}%`}
                        arcLabelsSkipAngle={DEGREES_PER_PERCENT * PERCENTAGE_THRESHOLD}
                        arcLabelsTextColor={(datum) => datum.data.textColor}
                        // Arc Link Labels
                        arcLinkLabel="label"
                        arcLinkLabelsTextColor="#000000"
                        arcLinkLabelsColor="#000000"
                        // Customisation
                        layers={["arcs", "arcLinkLabels", "arcLabels", "legends", PieChartGoalLine]}
                        // Interactivity
                        tooltip={PieChartTooltip}
                    />
                </div>
            </Col>
            <Col span={4}>
                {
                    imageFormats.map(format => (
                        <Button
                            key={`download-${id}-${format}`}
                            disabled={imageLoading}
                            icon={<PictureOutlined />}
                            loading={imageLoading}
                            onClick={async () => await onClickDownloadImage(format)}
                            style={{ marginBottom: 10 }}
                        >
                            {`.${format}`}
                        </Button>
                    ))
                }
            </Col>
        </Row>
    );
};