import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const { Title } = Typography;


type UserGuideProps = {
    isAdmin: boolean;
}


export const UserGuide = ({ isAdmin }: UserGuideProps) => {
    const { t } = useTranslation();

    const [showUserGuideModal, setShowUserGuideModal] = useState<boolean>(false);

    return (
        <>
            <Button
                onClick={() => setShowUserGuideModal(true)}
                icon={<QuestionCircleOutlined />}
                title={t("admin.tags.divisions.edit.userGuide.title")}
            >
                {t("admin.tags.divisions.edit.userGuide.title")}
            </Button>
            <Modal
                title={
                    <Title level={3}>
                        {t("reports.userGuide.title")}
                    </Title>
                }
                closable
                footer={[
                    <Button
                        key="close-user-guide"
                        onClick={() => setShowUserGuideModal(false)}
                        title={t("reports.userGuide.close")}
                    >
                        {t("reports.userGuide.close")}
                    </Button>
                ]}
                onCancel={() => setShowUserGuideModal(false)}
                open={showUserGuideModal}
                width="50%"
            >
                <p>{t("reports.userGuide.content.intro")}</p>
                <p>
                    <div><b>{t("reports.userGuide.content.overall.title")}</b></div>
                    <div>{t("reports.userGuide.content.overall.lines.1")}</div>
                    <div>{t("reports.userGuide.content.overall.lines.2")}</div>
                </p>
                <p>
                    <div><b>{t("reports.userGuide.content.division.title")}</b></div>
                    <div>{t("reports.userGuide.content.division.lines.1")}</div>
                    <div>{t("reports.userGuide.content.division.lines.2")}</div>
                    <div>{t("reports.userGuide.content.division.lines.4")}</div>
                </p>
                <p>
                    <div><b>{t("reports.userGuide.content.department.title")}</b></div>
                    <div>{t("reports.userGuide.content.department.lines.1")}</div>
                    <div>{t("reports.userGuide.content.department.lines.2")}</div>
                    <div>{t("reports.userGuide.content.department.lines.4")}</div>
                </p>
                {
                    isAdmin && 
                        <>
                            <p>
                                <div><b>{t("reports.userGuide.content.administrators.title")}</b></div>
                                <div>{t("reports.userGuide.content.administrators.description")}</div>
                            </p>
                            <p>
                                <div><b>{t("reports.userGuide.content.adminReport.title")}</b></div>
                                <div>{t("reports.userGuide.content.adminReport.lines.1")}</div>
                                <div>{t("reports.userGuide.content.adminReport.lines.2")}</div>
                                <div>{t("reports.userGuide.content.adminReport.lines.3")}</div>
                            </p>
                        </>
                }
            </Modal>
        </>
    );
};