import { Column, ColumnConfig, Line, LineConfig } from "@ant-design/plots";
import { Col, Row, Select } from "antd";
import { useState } from "react";
import { IChartData } from "../../selectors/ChartData";

interface IProps {
    data: IChartData[],
    loading: boolean,
    options?: object,
    columnOptions?: object,
    lineOptions?: object
}

const defaultChartConfig = ({
    xField: "groupedDate",
    yField: "percent",
    seriesField: "attribute",
    width: 400,
    height: 300,
    legend: {
        position: "right"
    },
    /*tooltip: {
        formatter: (datum) =>
            ({ name: datum.attribute ? datum.attribute : datum.category, value: `${Number(datum.percent).toFixed(2)}%` })
    },*/
    xAxis: {
        type: "cat",
        label: {
            formatter: (text: string) => new Date(text).toLocaleString(navigator.language, { month: "short", year: "numeric" } as Intl.DateTimeFormatOptions)
        }
    },
    yAxis: {
        label: {
            formatter: (text: string) => `${text}%`
        },
        //maxLimit: 100
    },
});

export const LineColumn = ({ data: chartData, loading, options, columnOptions, lineOptions }: IProps) => {

    const [chartMode, setChartMode] = useState("column");
    const customOptions = {
        ...defaultChartConfig,
        data: chartData,
        loading: loading,
        smooth: true,
        ...lineOptions,
        ...options
    };

    const customLineOptions = { ...customOptions, ...lineOptions } as LineConfig;
    const customColumnOptions = { ...customOptions, ...columnOptions } as ColumnConfig;

    return <Row
        gutter={[16, 16]}
        justify="center"
    >
        {
            false && <Col span={24}>
                <Row>
                    <Col offset={20} span={4}>
                        <Select
                            style={{ width: "130px", float: "right" }}
                            onChange={(e) => setChartMode(e)}
                            defaultValue={chartMode}
                            value={chartMode}
                        >
                            <Select.Option disabled value={"line"}>Line</Select.Option>
                            <Select.Option value={"column"}>Column</Select.Option>
                        </Select>
                    </Col>
                </Row>

            </Col>
        }
        <Col span={24}>
            {
                chartMode === "line" && <Line
                    {...customLineOptions}
                />
            }
            {
                chartMode === "column" && <Column
                    {...customColumnOptions}
                />
            }

        </Col>
    </Row>;
};