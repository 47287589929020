import { PictureOutlined } from "@ant-design/icons";
import { BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { BasicTooltip } from "@nivo/tooltip";
import { Button, Col, Row } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

import { getBarChartTheme } from "./styling";
import { FilterState } from "../Reports";
import { IChartData } from "../../../selectors/ChartData";
import { createExportFilename, downloadSvgAsImage, ImageFormat } from "../../../utils/imageExport";


type BarChartProps = {
    barChartData: BarChartDatum[];
    barChartKeys: string[];
}

type BarChartDatum = BarChartDatumPartial & {
    grouping: string;
}

type BarChartDatumPartial = {
    [key: string]: number | string;
}

type TargetProgressBarChartProps = {
    category: string;
    data: IChartData[];
    filterState: FilterState;
    numberOfDatasets: number;
}


const mapProgressChartId = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "exceeds":
        return "Exceeds";
    case "lt5":
        return "Within 5%";
    case "lt10":
        return "Within 10%";
    case "gt10":
        return "More than 10%";
    default:
        return "";
    }
};

const mapDateGroupingString = (dateGrouping: string): string => dayjs(dateGrouping).isValid() 
    ? dayjs(dateGrouping).format("MMM YY") : dateGrouping;

const getBarChartProps = (data: IChartData[]): BarChartProps => {
    const barChartKeys = Array.from(new Set<string>(data.map(({ targetState }) => mapProgressChartId(targetState))));
    const groupings = Array.from(new Set<string>(data.map(({ groupedDate }) => mapDateGroupingString(groupedDate) )));
    const barChartData = groupings
        .map(grouping => {
            const groupingData = data.filter(({ groupedDate }) => grouping === mapDateGroupingString(groupedDate));
            const totalValue = groupingData.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
            const values = Object.fromEntries(groupingData.map(({ targetState, value }) => (
                [mapProgressChartId(targetState), ((value / totalValue) * 100)]
            )));

            return { grouping, ...values };
        })
        .sort((a, b) => a.grouping.localeCompare(b.grouping));

    return { barChartData, barChartKeys };
};

const getProgressChartColor = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "Exceeds":
        return "#477302";
    case "Within 5%":
        return "#D3F8DC";
    case "Within 10%":
        return "#DE6363";
    case "More than 10%":
        return "#850002";
    default:
        return "#000000";
    }
};

const getProgressChartTextColor = (targetStateConcatCategory: string): string => {
    const index = targetStateConcatCategory.split("_")[0];
    switch (index) {
    case "Exceeds":
        return "#FFFFFF";
    case "Within 5%":
        return "#000000";
    case "Within 10%":
        return "#FFFFFF";
    case "More than 10%":
        return "#FFFFFF";
    default:
        return "#FFFFFF";
    }
};


const BarChartTooltip = ({ id, value, color }: BarTooltipProps<BarChartDatum>): JSX.Element => (
    <BasicTooltip
        id={id}
        value={`${Math.round(value)}%`}
        enableChip={true}
        color={color}
    />
);


export const TargetProgressBarChart = ({ category, data, filterState, numberOfDatasets }: TargetProgressBarChartProps) => {
    const id = `${category}-target-progress-bar-chart`;
    const containerId = `${id}-container`;
    const imageFormats: ImageFormat[] = [ImageFormat.PNG, ImageFormat.JPEG];

    const [imageLoading, setImageLoading] = useState<boolean>(false);

    const { barChartData, barChartKeys } = getBarChartProps(data);
    const theme = getBarChartTheme();

    const onClickDownloadImage = async (imageFormat: ImageFormat): Promise<void> => {
        setImageLoading(true);
        const filename = createExportFilename({ suffix: id, filterState });
        await downloadSvgAsImage(containerId, filename, imageFormat);
        setImageLoading(false);
    };

    return (
        <Row align="top" gutter={[16, 32]}>
            <Col offset={1} span={11}>
                Number of Datasets = {numberOfDatasets}
            </Col>
            <Col span={12}>
                <Row justify="end">
                    {
                        imageFormats.map(format => (
                            <Button
                                key={`download-${id}-${format}`}
                                disabled={imageLoading}
                                icon={<PictureOutlined />}
                                loading={imageLoading}
                                onClick={async () => await onClickDownloadImage(format)}
                                style={{ marginLeft: 10 }}
                            >
                                {`.${format}`}
                            </Button>
                        ))
                    }
                </Row>
            </Col>
            <Col span={24}>
                <Row>
                    <div id={containerId} style={{ height: 150, width: "100%" }}>
                        <ResponsiveBar
                            // Base
                            data={barChartData}
                            indexBy="grouping"
                            keys={barChartKeys}
                            groupMode="stacked"
                            layout="horizontal"
                            valueScale={{ type: "linear" }}
                            indexScale={{ type: "band", round: true }}
                            minValue={0}
                            maxValue={100}
                            valueFormat=">-.3r"
                            padding={0.25}
                            margin={{ top: 35, bottom: 30, left: 75, right: 15 }}    
                            // Style
                            theme={theme}
                            colors={(datum) => getProgressChartColor(datum.id.toString())}
                            colorBy="id"
                            borderColor={{ from: "color", modifiers: [["darker", 1]] }}
                            borderWidth={1}
                            // Labels
                            enableLabel
                            label={(datum) => `${Math.round(datum.value || 0)}%`}
                            labelSkipWidth={1}
                            labelTextColor={(datum) => getProgressChartTextColor(datum.data.id.toString())}
                            // Grid & Axes
                            enableGridY={false}
                            enableGridX={true}
                            gridXValues={[0, 20, 40, 60, 80, 100]}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickValues: [0, 20, 40, 60, 80, 100],
                            }}
                            // Interactivity
                            tooltip={BarChartTooltip}
                            // Legends
                            legends={[{
                                anchor: "top-left",
                                dataFrom: "keys",
                                direction: "row",
                                justify: false,
                                itemDirection: "left-to-right",
                                itemHeight: 20,
                                itemsSpacing: 0,
                                itemWidth: 110,
                                symbolSize: 15,
                                translateX: 5,
                                translateY: -30,
                            }]}    
                        />
                    </div>
                </Row>
            </Col>
        </Row>
    );
};