const ORDERED_DEFAULT_DIVERSITY_CATEGORIES = ["GENDER", "ETHNICITY", "DISABILITY", "SED"];

export const sortDiversityCategories = (compareA: string, compareB: string) => {
    const a = compareA.toLocaleUpperCase();
    const b = compareB.toLocaleUpperCase();

    if (ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(a) || ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(b)) {
        if (ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(a) && ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(b)) {
            return ORDERED_DEFAULT_DIVERSITY_CATEGORIES.indexOf(a) > ORDERED_DEFAULT_DIVERSITY_CATEGORIES.indexOf(b) ? 1 : -1;
        } else if (ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(a) && !ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(b)) {
            return -1;
        } else if (!ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(a) && ORDERED_DEFAULT_DIVERSITY_CATEGORIES.includes(b)) {
            return 1;
        }
    }
    
    return a.localeCompare(b);
};