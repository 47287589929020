import { PlusOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Alert, Button, Card, Col, Form, FormInstance, FormListFieldData, Input, Row, Space } from "antd";
import { useEffect, useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useCreateDiversityCategory } from "../../../hooks/programHooks";
import { sortDiversityCategories } from "../../../utils/sortDiversityCategories";
import CustomHelmet from "../../../components/CustomHelmet";
import { GetAllCategories, GetAllCategories_categories } from "../../../graphql/__generated__/GetAllCategories";
import { CreateCategoryInput } from "../../../graphql/__generated__/globalTypes";
import { GET_ALL_CATEGORIES } from "../../../graphql/__queries__/GetAllCategories.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";


type DiversityCategory = GetAllCategories_categories | CreateCategoryInput;

type EditDiversityCategoriesForm = {
    categories: DiversityCategory[];
};

type DiversityCategoryInputProps = {
    field: FormListFieldData;
    form: FormInstance<EditDiversityCategoriesForm>;
    initialValues: { categories: readonly GetAllCategories_categories[] };
    t: TFunction<"translation", undefined>;
};


const DiversityCategoryInput = ({ field, form, initialValues, t }: DiversityCategoryInputProps) => {
    const { name, ...restField } = field;

    const validateNameLength = async (value: string): Promise<void | Error> => {
        if (value.length > 30) {
            return Promise.reject(new Error(t("admin.categories.inputs.name.tooLong")));
        }
        return Promise.resolve();
    };

    const fieldValue = form.getFieldValue(["categories", name, "name"]);
    const inputDisabled = initialValues.categories.map(category => category.name).includes(fieldValue);

    return (
        <Card style={{ marginBottom: 16 }}>
            <Col span={24}>
                <Form.Item
                    {...restField}
                    label="Name"
                    labelAlign="left"
                    labelCol={{ span: 3 }}
                    name={[name, "name"]}
                    rules={[
                        { required: true, message: t("admin.categories.inputs.name.required")},
                        { validator: (_, value: string) => validateNameLength(value)},
                    ]}
                    validateFirst
                    validateTrigger="onBlur"
                >
                    <Input disabled={inputDisabled} placeholder={t("admin.categories.inputs.name.placeholder")} />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    {...restField}
                    label="Description"
                    labelAlign="left"
                    labelCol={{ span: 3 }}
                    name={[name, "description"]}
                    rules={[
                        { required: true, message: t("admin.categories.inputs.description.required")},
                    ]}
                    validateFirst
                    validateTrigger="onBlur"
                >
                    <Input.TextArea
                        disabled={inputDisabled}
                        maxLength={500}
                        placeholder={t("admin.categories.inputs.description.placeholder")}
                        rows={3}
                        showCount
                    />
                </Form.Item>
            </Col>
        </Card>
    );
};

const ErrorBlock = ({ message }: { message: string }) => (
    <Row style={{ paddingTop: 16, paddingBottom: 16 }}>
        <Col offset={1} span={22}>
            <Alert closable message={message} showIcon type="error"/>
        </Col>
    </Row>
);

const useAdminDiversityCategoryQueries = () => {
    const categoriesResponse = useQueryWithErrorHandling<GetAllCategories>(
        GET_ALL_CATEGORIES, "categories", { fetchPolicy: "network-only" }
    );

    const categories = [...(categoriesResponse.data?.categories || [])]
        .sort((a, b) => sortDiversityCategories(a.name, b.name));

    return {
        loading: categoriesResponse.loading,
        categories,
        refresh: () => categoriesResponse.refetch(),
    };
};

const isCreateCategoryInput = (
    category: GetAllCategories_categories | CreateCategoryInput
): category is CreateCategoryInput => (
    !(category as GetAllCategories_categories).id &&
    !!(category as CreateCategoryInput).name &&
    !!(category as CreateCategoryInput).description
);


export const ManageDiversityCategories = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [editDiversityCategoriesForm] = Form.useForm<EditDiversityCategoriesForm>();

    const { loading, categories, refresh } = useAdminDiversityCategoryQueries();

    const initialValues = useMemo(() => ({ categories: categories }), [categories]);
    const createDiversityCategory = useCreateDiversityCategory();

    useEffect(() => { 
        editDiversityCategoriesForm.resetFields();
    }, [editDiversityCategoriesForm, categories]);

    const saving = createDiversityCategory.inFlight;

    const onFinish = async (values: EditDiversityCategoriesForm) => {
        const categoriesToCreate = values.categories
            .filter((category): category is CreateCategoryInput => isCreateCategoryInput(category));

        if (categoriesToCreate.length > 0) {
            for (const category in categoriesToCreate) {
                await createDiversityCategory.run(categoriesToCreate[category]);
            }
        }

        refresh();
        return;
    };

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={t("admin.categories.title")} />

            {/* Page Header */}
            <PageHeader
                onBack={() => navigate("/")}
                title={t("admin.categories.title")}
                subTitle={t("admin.categories.subtitle")}
            />

            {/* Edit Diversity Categories Form */}
            <Form
                autoComplete="off"
                form={editDiversityCategoriesForm}
                initialValues={initialValues}
                onFinish={onFinish}
            >
                {/* Errors */}
                {createDiversityCategory.error && <ErrorBlock message={createDiversityCategory.error.message} />}

                {/* Diversity Categories */}
                <Form.List name="categories">
                    {(fields, operations) =>
                        <>
                            <Row style={{ paddingTop: 16 }}>
                                <Col offset={1} span={22}>
                                    {fields.map((field) => (
                                        <DiversityCategoryInput 
                                            key={field.key}
                                            field={field}
                                            form={editDiversityCategoriesForm}
                                            initialValues={initialValues}
                                            t={t}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 16 }}>
                                <Col offset={1} span={22}>
                                    <Form.Item>
                                        <Button 
                                            block
                                            icon={<PlusOutlined />}
                                            loading={loading || saving}
                                            onClick={() => operations.add()} 
                                            type="dashed" 
                                        >
                                            {t("admin.categories.actions.add")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                </Form.List>

                <Row gutter={[16, 16]} align="middle" justify="center" style={{ paddingTop: 16 }}>
                    <Form.Item>
                        <Space>
                            {/* Save Button */}
                            <Button 
                                htmlType="submit"
                                loading={loading || saving}
                                title={t("admin.categories.actions.save")}
                                type="primary" 
                            >
                                {t("admin.categories.actions.save")}
                            </Button>
                            
                            {/* Cancel Button */}
                            <Button
                                loading={loading || saving}
                                onClick={() => navigate("/")}
                                title={t("admin.categories.actions.cancel")}
                            >
                                {t("admin.categories.actions.cancel")}
                            </Button>
                        </Space>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
};