import { gql } from "@apollo/client";

export const ADMIN_GET_DATASET_TO_EXPORT = gql`
  query AdminGetDatasetToExport($id: ID!) {
    dataset(id: $id) {
      id
      name
      program {
        reportingPeriods {
          id
          range
        }
      }
      records {
        id
        publicationDate
        customColumnValues {
          id
          customColumn {
            id
            name
            type
          }
          value
        }
      }
      publishedRecordSets {
        id
        reportingPeriodId
        begin
        end
        document
      }
      customColumns {
        id
      }
    }
  }
`;
