import { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";

import { 
    ExtraResultSummaryStats,
    getHighPerformersForCategories, 
    GetExtraResultSummaryStats, 
    getRoundedPercent, 
    groupPublishedRecords, 
    NO_DATA_TO_DISPLAY_SECTION, 
    ResultSummary, 
    ResultSummaryContent, 
    ResultSummaryData, 
    ResultSummarySection,
    ResultSummarySectionType,
    stringIsNotUndefined,
    filterReportsDataByFilterState
} from "./index";
import { IPublishedRecordSetDocument } from "../../DatasetDetails/PublishedRecordSet";
import { GetReportsData_reportsData_publishedRecordSets } from "../../../graphql/__generated__/GetReportsData";
import { joinArrayStringItems } from "../../../utils/array";


const getFilterSelectionsAsText = (data: ResultSummaryData): string => {
    const { departments, divisions, miscTags, programs, teams } = data.filterState;
    const filterSelections = [...divisions, ...departments, ...miscTags, ...programs, ...teams];
    return filterSelections.length > 0 ? joinArrayStringItems(filterSelections.map(({ name }) => name)) : "BBC Overall";
};

const addEngagementSection = (
    data: ResultSummaryData,
    date: Dayjs, 
    extraStats: ExtraResultSummaryStats,
    filterSelectionsAsText: string,
    sections: ResultSummarySection[],
): void => {
    const { publishedRecordSets, unpublishedDatasets, offAirDatasets } = data.reportsData.currentMonth;
    const publishedTeamIds = publishedRecordSets
        .map(published => published.dataset?.program?.team?.id)
        .filter(stringIsNotUndefined);
    const unpublishedTeamIds = unpublishedDatasets
        .map(unpublished => unpublished.dataset.program?.team?.id)
        .filter(stringIsNotUndefined);
    const offairDatasetIds = offAirDatasets
        .map(offair => offair.id)
        .filter(stringIsNotUndefined);

    const numberOfTeamsUnpublished = new Set(unpublishedTeamIds).size;
    const numberOfTeamsShouldPublish = new Set([...publishedTeamIds, ...unpublishedTeamIds]).size;
    const numberOfDatasetsOffAir = new Set(offairDatasetIds).size;

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.ADMIN,
        header: "Engagement:",
        content: [{
            id: uuidv4(),
            lines: [
                {
                    id: uuidv4(),
                    text: `${extraStats.numberOfTeamsCreated.currentMonth} team(s) in ${filterSelectionsAsText} signed up to 50:50 in ${date.format("MMMM")}. It was ${extraStats.numberOfTeamsCreated.previousMonth} in ${date.subtract(1, "month").format("MMMM")}.`,
                },
                {
                    id: uuidv4(),
                    text: `${extraStats.numberOfDatasetsCreated.currentMonth} dataset(s) in ${filterSelectionsAsText} were added to 50:50 this month. It was ${extraStats.numberOfDatasetsCreated.previousMonth} in ${date.subtract(1, "month").format("MMMM")}.`,
                },
                {
                    id: uuidv4(),
                    text: `${numberOfTeamsUnpublished} of ${numberOfTeamsShouldPublish} team(s) who should have published their data didn't do so in ${date.format("MMMM")}.`,
                },
                {
                    id: uuidv4(),
                    text: `${numberOfDatasetsOffAir} dataset(s) were off air or not expected to file.`,
                },
            ]
        }],
    });
};

const getGroupTargetPercent = (
    publishedRecords: readonly GetReportsData_reportsData_publishedRecordSets[],
    category: string
) => {
    const filteredPublishedRecords = publishedRecords.filter(publishedRecord => (
        !!(publishedRecord?.document as IPublishedRecordSetDocument)?.record?.["Everyone"]?.[category]
    ));
    const groupTargetPercent = filteredPublishedRecords[0]?.dataset?.program?.targets
        .find(target => target.category.name === category)?.target || 0;
    
    return (groupTargetPercent * 100);
};

const addPerformanceSection = (data: ResultSummaryData, date: Dayjs, sections: ResultSummarySection[]): void => {
    const { categories, reportsData, tz } = data;
    const { publishedRecordSets } = reportsData.currentMonth;
    const currentGroupedPublishedRecords = groupPublishedRecords(categories, publishedRecordSets, tz);

    const onTargetContent: ResultSummaryContent = {
        id: uuidv4(),
        lines: categories.map(category => {
            const stats = currentGroupedPublishedRecords?.[category];
            return {
                id: uuidv4(),
                text: `${stats ? stats.exceeds : 0} out of ${stats ? stats.count : 0} dataset(s) (${stats ? getRoundedPercent(stats.exceeds, stats.count) : 0}%) achieved their ${category} target.`,
            };
        }),
    };

    const almostOnTargetContent: ResultSummaryContent = {
        id: uuidv4(),
        lines: categories.map((category, index) => {
            const stats = currentGroupedPublishedRecords?.[category];
            const groupTargetPercent = index === 0 ? getGroupTargetPercent(publishedRecordSets, category) : 0;
            const achievementText = index === 0 && groupTargetPercent > 10
                ? `${groupTargetPercent - 10}-${groupTargetPercent - 1}% in ${category}`
                : `within a 10% margin of their ${category} target`;
            return {
                id: uuidv4(),
                text: `${stats ? (stats.lt10 + stats.lt5) : 0} out of ${stats ? stats.count : 0} dataset(s) achieved ${achievementText}.`,
            };
        }),
    };

    const offTargetContent: ResultSummaryContent = {
        id: uuidv4(),
        lines: categories.map(category => {
            const stats = currentGroupedPublishedRecords?.[category];
            return {
                id: uuidv4(),
                text: `${stats ? stats.gt10 : 0} out of ${stats ? stats.count : 0} dataset(s) missed their ${category} targets.`,
            };
        }),
    };

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.ADMIN,
        header: `Achievements / Performance in ${date.format("MMMM YYYY")}:`,
        content: [onTargetContent, almostOnTargetContent, offTargetContent],
    });
};

const addHighPerformerSection = (data: ResultSummaryData, sections: ResultSummarySection[]): void => {
    const { categories, reportsData } = data;
    const highPerformers = getHighPerformersForCategories(categories.slice(1), reportsData.currentMonth.publishedRecordSets);
    const filteredHighPerformers = highPerformers.filter(categoryHighPerformer => categoryHighPerformer.result > 5);

    if (filteredHighPerformers.length > 0) {
        sections.push({
            id: uuidv4(),
            type: ResultSummarySectionType.ADMIN,
            header: "High Performers:",
            content: [{
                id: uuidv4(),
                lines: filteredHighPerformers
                    .map(categoryHighPerformer => ({
                        id: uuidv4(),
                        text: `${joinArrayStringItems(categoryHighPerformer.datasetNames)} achieved the highest percentage of ${Math.round(categoryHighPerformer.result)}% in the ${categoryHighPerformer.categoryName} category.`
                    }))
            }],
        });    
    }
};

const addConsistentHighPerformerSection = (extraStats: ExtraResultSummaryStats, sections: ResultSummarySection[]): void => {
    const { fullYear, halfYear, categoryName } = extraStats.highPerformingDatasets;

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.ADMIN,
        header: "Consistently High Performing Datasets:",
        content: [{
            id: uuidv4(),
            lines: [
                {
                    id: uuidv4(),
                    text: `${fullYear} dataset(s) reached their ${categoryName} target 12 months in a row. This includes monthly and quarterly datasets only.`,
                },
                {
                    id: uuidv4(),
                    text: `${halfYear} dataset(s) reached their ${categoryName} target 6 months in a row. This includes monthly and quarterly datasets only.`,
                }
            ],
        }],
    });
};

const addAnnualProgressSection = (extraStats: ExtraResultSummaryStats, sections: ResultSummarySection[]): void => {
    const { annualProgress } = extraStats;

    sections.push({
        id: uuidv4(),
        type: ResultSummarySectionType.ADMIN,
        header: "Annual Progress:",
        content: [{
            id: uuidv4(),
            lines: annualProgress.map(({ categoryName, datasetsOnTarget }) => ({
                id: uuidv4(),
                text: `Proportion of dataset(s) who achieved their ${categoryName} target in March of each year: ${joinArrayStringItems(datasetsOnTarget.map(({ proportion, year }) => `${proportion}% March ${year}`))}`
            })),
        }],
    });
};

export default async (
    data: ResultSummaryData, 
    date: Dayjs,
    getExtraStats: GetExtraResultSummaryStats,
): Promise<ResultSummary> => {    
    const { data: extraStats } = await getExtraStats();
    const filteredData = filterReportsDataByFilterState(data);
    const filterSelectionsAsText = getFilterSelectionsAsText(data);

    const title = `50:50 Admin Report for ${date.format("MMMM YYYY")} - ${filterSelectionsAsText}`;
    const sections: ResultSummarySection[] = [];
    
    addEngagementSection(filteredData, date, extraStats, filterSelectionsAsText, sections);
    addPerformanceSection(filteredData, date, sections);
    addHighPerformerSection(filteredData, sections);
    addConsistentHighPerformerSection(extraStats, sections);    
    addAnnualProgressSection(extraStats, sections);

    if (sections.length === 0) {
        sections.push(NO_DATA_TO_DISPLAY_SECTION);
    }

    return { title, sections };
};