import { 
    AppstoreAddOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    EditOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { useLazyQuery } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Row, Table, TableColumnsType } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useMemo, useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { CreateProgram, CreateProgramFormValues } from "./CreateProgram";
import "./ManageDatasets.css";
import { mapCsvPublishedRecordSets } from "../../DatasetDetails/datasetDetailsExport";
import CustomHelmet from "../../../components/CustomHelmet";
import { AdminGetAllPrograms, AdminGetAllPrograms_programs } from "../../../graphql/__generated__/AdminGetAllPrograms";
import { AdminGetDatasetToExport, AdminGetDatasetToExportVariables } from "../../../graphql/__generated__/AdminGetDatasetToExport";
import { ADMIN_GET_ALL_PROGRAMS } from "../../../graphql/__queries__/AdminGetAllPrograms.gql";
import { ADMIN_GET_DATASET_TO_EXPORT } from "../../../graphql/__queries__/AdminGetDatasetToExport.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { CsvExportItem, generateCsvExport } from "../../../utils/csvExport";

dayjs.extend(customParseFormat);

const { Search } = Input;


type DownloadDatasetButtonProps = {
    program: Program
    t: TFunction
}

type Program = {
    programId: string;
    datasetId: string;
    datasetName: string;
    datasetLastUpdated: string;
    active: boolean;
    categories: string[];
    contentTypes: string[];
    department: string;
    division: string;
    miscellaneousTags: string[];
    platforms: string[];
}


const DATE_FORMAT = "DD/MM/YYYY";

const mapCsvPrograms = (programs: Program[], t: TFunction): CsvExportItem[] => programs.map(program => (
    {
        [t("admin.dataset.index.columns.datasetName")]: "\"" + program.datasetName + "\"",
        [t("admin.dataset.index.columns.datasetLastUpdated")]: "\"" + program.datasetLastUpdated + "\"",
        [t("admin.dataset.index.columns.categories")]: "\"" + program.categories.join(", ") + "\"",
        [t("admin.dataset.index.columns.contentTypes")]: "\"" + program.contentTypes.join(", ") + "\"",
        [t("admin.dataset.index.columns.department")]: "\"" + program.department + "\"",
        [t("admin.dataset.index.columns.division")]: "\"" + program.division + "\"",
        [t("admin.dataset.index.columns.miscellaneousTags")]: "\"" + program.miscellaneousTags.join(", ") + "\"",
        [t("admin.dataset.index.columns.platforms")]: "\"" + program.platforms.join(", ") + "\"",
    }
));

const mapRawPrograms = (rawPrograms: readonly AdminGetAllPrograms_programs[]): Program[] => (
    rawPrograms
        .filter(program => program.datasets.length > 0)
        .map(program => (
            {
                programId: program.id,
                datasetId: program.datasets[0].id,
                datasetName: program.datasets[0].name,
                datasetLastUpdated: program.datasets[0].lastUpdated 
                    ? dayjs(program.datasets[0].lastUpdated).format(DATE_FORMAT) : "N/A",
                active: !program.deleted,
                categories: program.targets.map(target => target.category.name),
                contentTypes: (program.contentTypes || []).map(contentType => contentType.name),
                department: program.department?.name || "",
                division: program.department?.division.name || "",
                miscellaneousTags: program.tags.map(tag => tag.name),
                platforms: (program.platforms || []).map(platform => platform.name),
            }
        ))
);

const applySearchFilter = (programs: Program[] | undefined, searchTerms: string[]) => (
    programs?.filter(({ datasetName, categories, contentTypes, miscellaneousTags, platforms }) => (
        searchTerms.every(term => (
            `${datasetName}
            :${categories.join(":")}
            :${contentTypes.join(":")}
            :${miscellaneousTags.join(":")}
            :${platforms.join(":")}`
        ).toLocaleLowerCase().includes(term.toLocaleLowerCase()))
    )) || []
);

const stringSorter = (a: string, b: string) => {
    if (a !== "") {
        if (b !== "") {
            return a.localeCompare(b);
        }
        return -1;
    }
    return 1;
};

const dateSorter = (a: dayjs.Dayjs, b: dayjs.Dayjs) => {
    if (a.isValid()) {
        if (b.isValid()) {
            if (a.isSame(b)) {
                return 0;
            }
            if (a.isBefore(b)) {
                return -1;
            }
            if (a.isAfter(b)) {
                return 1;
            }
        }
        return 1;
    }
    return -1;
};

const DownloadDatasetButton = ({ program, t }: DownloadDatasetButtonProps) => {
    const [downloadDatasetUrl, setDownloadDatasetUrl] = useState<string>("");
    const csvLink = useRef(null);

    const [
        getDataset, { loading: getDatasetLoading }
    ] = useLazyQuery<AdminGetDatasetToExport, AdminGetDatasetToExportVariables>(ADMIN_GET_DATASET_TO_EXPORT);

    const downloadDataset = async (datasetId: string) => {
        const rawDataset = await getDataset({ variables: { id: datasetId }, fetchPolicy: "network-only" });
        const { titleKeys, mappedRows } = mapCsvPublishedRecordSets(rawDataset.data?.dataset);
        if (!titleKeys || !mappedRows) {
            return;
        }

        const url = generateCsvExport({ items: mappedRows, headers: titleKeys });
        setDownloadDatasetUrl(url);
        (csvLink.current as unknown as React.ElementRef<"a">).click();
    };

    return (
        <>
            <Button
                disabled={getDatasetLoading}
                icon={<DownloadOutlined />}
                key={`download-${program.datasetId}`}
                onClick={async () => await downloadDataset(program.datasetId)}
                shape="round"
                title={t("admin.dataset.index.columns.exportDataset")}
                type="primary"
            />
            <a
                download={`${program.datasetName}.csv`}
                hidden 
                href={downloadDatasetUrl}
                ref={csvLink}
                title={t("admin.dataset.index.columns.exportDataset")}
            >
                {t("admin.dataset.index.columns.exportDataset")}
            </a>
        </>
    );
};


export const ManageDatasets = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [createProgramForm] = Form.useForm<CreateProgramFormValues>();

    const [exportAllDatasetsUrl, setExportAllDatasetsUrl] = useState<string>("");
    const [searchTerms, setSearchTerms] = useState<string[]>([]);
    const [showCreateProgram, setShowCreateProgram] = useState(false);
    
    const rawPrograms = useQueryWithErrorHandling<AdminGetAllPrograms>(
        ADMIN_GET_ALL_PROGRAMS, "programs", { fetchPolicy: "cache-first" }
    );

    const programs = useMemo(() => mapRawPrograms(rawPrograms.data?.programs || []), [rawPrograms]);

    const categories = useMemo(() => (
        [...new Set(programs.flatMap(program => program.categories.map(category => category)))]
    ), [programs]);
    const contentTypes = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.contentTypes.map(contentType => contentType)))];
        return deduped.filter(contentType => contentType !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);
    const departments = useMemo(() => {
        const deduped = [...new Set(programs.map(program => program.department))];
        return deduped.filter(department => department !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);
    const divisions = useMemo(() => {
        const deduped = [...new Set(programs.map(program => program.division))];
        return deduped.filter(division => division !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);
    const miscellaneousTags = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.miscellaneousTags.map(tag => tag)))];
        return deduped.filter(tag => tag !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);
    const platforms = useMemo(() => {
        const deduped = [...new Set(programs.flatMap(program => program.platforms.map(platform => platform)))];
        return deduped.filter(platform => platform !== "").sort((a, b) => stringSorter(a, b));
    }, [programs]);

    const filteredPrograms = useMemo(() => (
        searchTerms.length ? applySearchFilter(programs, searchTerms) : programs
    ), [programs, searchTerms]);

    const exportAllDatasets = () => {
        const csvPrograms = mapCsvPrograms(programs, t);
        const url = generateCsvExport({ items: csvPrograms });
        setExportAllDatasetsUrl(url);
    };

    const columns: TableColumnsType<Program> = [
        {
            title: t("admin.dataset.index.columns.datasetName"),
            key: "dataset-name",
            dataIndex: "datasetName",
            defaultSortOrder: "ascend",
            fixed: "left",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            sorter: (a, b) => stringSorter(a.datasetName, b.datasetName),
        },
        {
            title: t("admin.dataset.index.columns.datasetLastUpdated"),
            key: "dataset-last-updated",
            dataIndex: "datasetLastUpdated",
            fixed: "left",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            sorter: (a, b) => dateSorter(dayjs(a.datasetLastUpdated, DATE_FORMAT), dayjs(b.datasetLastUpdated, DATE_FORMAT)),
            width: 140,
        },
        {
            title: t("admin.dataset.index.columns.categories"),
            key: "categories",
            dataIndex: "categories",
            filters: categories.map(category => ({ text: category, value: category })),
            onFilter: (value, record) => record.categories.some(category => category === value),
            render: (categories: string[]) => categories.join(", "),
            width: 210,
        },
        {
            title: t("admin.dataset.index.columns.division"),
            key: "division",
            dataIndex: "division",
            filters: divisions.map(division => ({ text: division, value: division })),
            onFilter: (value, record) => record.division === value,
            width: 120,
        },
        {
            title: t("admin.dataset.index.columns.department"),
            key: "department",
            dataIndex: "department",
            filters: departments.map(department => ({ text: department, value: department })),
            filterSearch: true,
            onFilter: (value, record) => record.department === value,
            width: 180,
        },
        {
            title: t("admin.dataset.index.columns.miscellaneousTags"),
            key: "miscellaneous-tags",
            dataIndex: "miscellaneousTags",
            filters: miscellaneousTags.map(tag => ({ text: tag, value: tag })),
            filterSearch: true,
            onFilter: (value, record) => record.miscellaneousTags.some(tag => tag === value),
            render: (miscellaneousTags: string[]) => miscellaneousTags.join(", "),
            width: 220,
        },
        {
            title: t("admin.dataset.index.columns.contentTypes"),
            key: "content-types",
            dataIndex: "contentTypes",
            filters: contentTypes.map(contentType => ({ text: contentType, value: contentType })),
            filterSearch: true,
            onFilter: (value, record) => record.contentTypes.some(contentType => contentType === value),
            render: (contentTypes: string[]) => contentTypes.join(", "),
            width: 140,
        },
        {
            title: t("admin.dataset.index.columns.platforms"),
            key: "platforms",
            dataIndex: "platforms",
            filters: platforms.map(platform => ({ text: platform, value: platform })),
            filterSearch: true,
            onFilter: (value, record) => record.platforms.some(platform => platform === value),
            render: (platforms: string[]) => platforms.join(", "),
            width: 110,
        },
        {
            title: t("admin.dataset.index.columns.viewDetails"),
            key: "view-details",
            dataIndex: "datasetId",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (datasetId: string) => (
                <a href={`/dataset/${datasetId}/details`}>
                    <Button icon={<InfoCircleOutlined />} title={t("admin.dataset.index.columns.viewDetails")}>
                        {t("admin.dataset.index.columns.viewDetails")}
                    </Button>
                </a>
            ),
            width: 150,
        },
        {
            title: t("admin.dataset.index.columns.active"),
            key: "active",
            dataIndex: "active",
            align: "center",
            defaultFilteredValue: ["true"],
            filters: [{ text: "Active", value: "true" }, { text: "Inactive", value: "false" }],
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            onFilter: (value: boolean | string | number, record: Program) => `${record.active}` === value,
            render: (active: boolean) => (active ? <CheckCircleOutlined /> : <CloseCircleOutlined />),
            width: 80,
        },
        {
            title: t("admin.dataset.index.columns.edit"),
            key: "edit-dataset-link",
            dataIndex: "programId",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (programId: string) => (
                <a href={`/admin/programs/${programId}`}>
                    <EditOutlined />
                </a>
            ),
            width: 60,
        },
        {
            title: t("admin.dataset.index.columns.records"),
            key: "export-dataset",
            align: "center",
            fixed: "right",
            onCell: () => ({ id: "datasets-index-fixed-column-cell" }),
            render: (_, program: Program) => <DownloadDatasetButton program={program} t={t} />,
            width: 100,
        },
    ];
    
    return (
        <Row gutter={[16, 16]}>
            {/* Page Title */}
            <CustomHelmet title={t("admin.dataset.index.title")} />

            {/* Page Header */}
            <Col span={24}>
                <PageHeader 
                    title={t("admin.dataset.index.title")}
                    extra={[

                        // Create New Programme //
                        <Button
                            title={t("admin.program.index.add")}
                            key="show-create-new-programme"
                            disabled={rawPrograms.loading}
                            icon={<AppstoreAddOutlined />}
                            onClick={() => setShowCreateProgram(true)}
                            type="primary"
                        >
                            {t("admin.program.index.add")}
                        </Button>,

                        // Bulk Edit Reporting Periods //
                        <Button
                            title={t("admin.program.index.reportingPeriods")}
                            key="bulk-edit-reporting-periods"
                            icon={<EditOutlined />}
                            onClick={() => navigate("/admin/programs/reporting-periods")}
                            type="primary"
                        >
                            {t("admin.program.index.reportingPeriods")}
                        </Button>,

                        // Export All Datasets //
                        <Button
                            title={t("admin.dataset.index.actions.exportAll")}
                            key="export-all-datasets"
                            disabled={rawPrograms.loading}
                            download="all-datasets.csv"
                            href={exportAllDatasetsUrl}
                            icon={<DownloadOutlined />}
                            onClick={exportAllDatasets}
                            shape="round"
                            type="primary"
                        >
                            {t("admin.dataset.index.actions.exportAll")}
                        </Button>
                    ]}
                />
            </Col>

            {/* Create New Programme Modal */}
            <Modal
                title={t("admin.program.create.title")}
                key="create-new-programme"
                cancelText={t("admin.program.create.cancel")}
                forceRender
                okText={t("admin.program.create.save")}
                onOk={() => createProgramForm.submit()}
                onCancel={() => {
                    setShowCreateProgram(false);
                    createProgramForm.resetFields();
                }}
                open={showCreateProgram}
            >
                <CreateProgram form={createProgramForm} />
            </Modal>

            {/* Search Datasets */}
            <Col span={8}>
                <Search 
                    allowClear
                    disabled={rawPrograms.loading}
                    onSearch={(input) => setSearchTerms(input.split(" "))} 
                    placeholder={t("admin.dataset.index.actions.search")}
                />
            </Col>

            {/* View Datasets */}
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={filteredPrograms}
                    loading={rawPrograms.loading}
                    rowKey={({ programId }) => programId}
                    scroll={{ x: 1700 }}
                    size="small"
                    tableLayout="fixed"
                />
            </Col>
        </Row>
    );
};