import { Card, Col, Row } from "antd";
import dayjs from "dayjs";

import { DateRange } from "./Reports";


type CreateEngagementSummaryProps = {
    categories: string[];
    published: EngagementSummaryDataset[];
    unpublished: EngagementSummaryDataset[];
    offAir: EngagementSummaryDataset[];
}

export type EngagementSummary = {
    totalNumberOfDatasets: number;
    totalNumberOfTeams: number;
    numberOfDatasetsCategoryBreakdown: Record<string, number>;
    numberOfTeamsCategoryBreakdown: Record<string, number>;
}

export type EngagementSummaryDataset = {
    datasetId: string;
    teamId?: string;
    trackedCategories: string[];
}

type EngagementSummaryProps = {
    dateRange: DateRange;
    engagementSummary: EngagementSummary | undefined;
    pageLoading: boolean;
}

const stringIsNotUndefined = (string: string | undefined): string is string => !!string;

export const createEngagementSummary = (
    { categories, published, unpublished, offAir }: CreateEngagementSummaryProps
): EngagementSummary => {
    const concatenatedDatasets = [...published, ...unpublished, ...offAir];

    const datasetIds = concatenatedDatasets.map(dataset => dataset.datasetId);
    const totalNumberOfDatasets = new Set(datasetIds).size;

    const teamIds = concatenatedDatasets.map(dataset => dataset.teamId).filter(stringIsNotUndefined);
    const totalNumberOfTeams = new Set(teamIds).size;

    const numberOfDatasetsCategoryBreakdown = categories.reduce((collection, category) => {
        const datasetIds = concatenatedDatasets
            .filter(dataset => dataset.trackedCategories.some(trackedCategory => trackedCategory === category))
            .map(dataset => dataset.datasetId);
        
        const numberOfDatasets = new Set(datasetIds).size;
        collection[category] = numberOfDatasets;
        
        return collection;
    }, {} as Record<string, number>);

    const numberOfTeamsCategoryBreakdown = categories.reduce((collection, category) => {
        const teamIds = concatenatedDatasets
            .filter(dataset => !!dataset.teamId)
            .filter(dataset => dataset.trackedCategories.some(trackedCategory => trackedCategory === category))
            .map(dataset => dataset.teamId);
        
        const numberOfTeams = new Set(teamIds).size;
        collection[category] = numberOfTeams;
        
        return collection;
    }, {} as Record<string, number>);

    return {
        totalNumberOfDatasets,
        totalNumberOfTeams,
        numberOfDatasetsCategoryBreakdown,
        numberOfTeamsCategoryBreakdown,
    };
};


export const EngagementSummary = ({ dateRange, engagementSummary, pageLoading }: EngagementSummaryProps) => {
    return (
        <>
            <Card
                title={
                    `${dayjs(dateRange.begin).format("MMM YYYY")} - ${dayjs(dateRange.end).format("MMM YYYY")}`
                }
                loading={pageLoading}
                size="small"
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <p><b>Total Number of Datasets</b>: {engagementSummary?.totalNumberOfDatasets}</p>
                        {
                            engagementSummary?.numberOfDatasetsCategoryBreakdown &&
                            Object.entries(engagementSummary.numberOfDatasetsCategoryBreakdown).map(breakdown => (
                                <p key={`dataset-stats-${breakdown[0]}`}>
                                    {`Datasets Tracking ${breakdown[0]}: ${breakdown[1]}`}
                                </p>
                            ))
                        }
                    </Col>
                    <Col span={24}>
                        <p><b>Total Number of Teams</b>: {engagementSummary?.totalNumberOfTeams}</p>
                        {
                            engagementSummary?.numberOfTeamsCategoryBreakdown &&
                            Object.entries(engagementSummary.numberOfTeamsCategoryBreakdown).map(breakdown => (
                                <p key={`team-stats-${breakdown[0]}`}>
                                    {`Teams Tracking ${breakdown[0]}: ${breakdown[1]}`}
                                </p>
                            ))
                        }
                    </Col>
                </Row>
            </Card>
        </>
    );
};